import { useState } from 'react';

import { notifications } from '@mantine/notifications';
import { CreateFileDto, CreateFolderDto, CreateLocationDto, CreateUserDto, FileDto, FileMetadataUploadResponseDto, FolderDto, LocationDto, ProfileDto, UpdateFolderDto, UpdateLocationDto } from '@braoshzmvavz/picnube-common';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { selectCurrentSpace } from 'src/state/profile/profileSlice';
import { openEmailVerificationModal } from '../components/modal/confirm';
import { UpdateFolderAccessDto } from '../model/UpdateFolderAccessDto';
import { useAuth } from './useAuth';

export enum APIError {
    NOT_FOUND = 'NOT_FOUND',
    ACCESS_DENIED = 'ACCESS_DENIED',
}

const useApi = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { auth, validateAuth } = useAuth();

    const currentSpace = useAppSelector(selectCurrentSpace);

    //const isDevEnv = process.env.NODE_ENV === 'development';
    //const API_BASE_URL = isDevEnv ? '' : `${process.env.REACT_APP_API_BASE_URL}/`
    const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`
    const API_PATH_PROFILE = API_BASE_URL + process.env.REACT_APP_API_PATH_PROFILE
    const API_PATH_FOLDERS = API_BASE_URL + process.env.REACT_APP_API_PATH_FOLDERS
    const API_PATH_UPLOAD = API_BASE_URL + process.env.REACT_APP_API_PATH_UPLOAD
    const API_PATH_FILES = API_BASE_URL + process.env.REACT_APP_API_PATH_FILES
    const API_PATH_LOCATIONS = API_BASE_URL + process.env.REACT_APP_API_PATH_LOCATIONS
    const API_PATH_USERS = API_BASE_URL + process.env.REACT_APP_API_PATH_USERS

    const buildHeaders = async () => {
        const token = await auth.currentUser.getIdToken();

        return {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    const apiRequest = async<T, R>(url: string, method: string, body?: T): Promise<R> => {
        if (!validateAuth()) return;

        const headers = await buildHeaders();

        setLoading(true);

        const response: Response = await fetch(url, {
            method: method,
            headers: headers,
            body: body ? JSON.stringify(body) : undefined
        });

        setLoading(false);

        if (!response.ok) {
            throw new Error((await response.json())?.message ?? 'Unknown error');
        }

        return response.json();
    }

    const fetchProfile = async (defaultSpaceId?: string): Promise<ProfileDto> => {
        if (!!defaultSpaceId) {
            return await apiRequest(`${API_PATH_PROFILE}?` + new URLSearchParams({
                spaceId: defaultSpaceId
            }), 'GET');
        }
        return await apiRequest(API_PATH_PROFILE, 'GET');
    }

    const fetchFiles = async (spaceId: string, folderId: string, page: number) => {
        return await apiRequest(`${API_PATH_FILES}/search?` + new URLSearchParams({
            spaceId: spaceId,
            folderId: folderId,
            page: '' + page,
        }), 'GET');
    }

    const createLocation = async (location: CreateLocationDto) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_LOCATIONS}`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(location)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const updateLocation = async (location: UpdateLocationDto) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_LOCATIONS}/${location.id}`,
                {
                    method: 'PUT',
                    headers: await buildHeaders(),
                    body: JSON.stringify(location)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const fetchFolders = async (spaceId: string): Promise<FolderDto[]> => {
        return await apiRequest(`${API_PATH_FOLDERS}?` + new URLSearchParams({
            spaceId: spaceId
        }), 'GET');
    }

    const fetchLocations = async (spaceId: string): Promise<LocationDto[]> => {
        return await apiRequest(`${API_PATH_LOCATIONS}?` + new URLSearchParams({
            spaceId: spaceId
        }), 'GET');
    }

    const searchFiles = async (spaceId: string, page: number): Promise<FileDto[]> => {
        return await apiRequest(`${API_PATH_FILES}/search?` + new URLSearchParams({
            spaceId: spaceId,
            page: '' + page,
            sort: 'name',
        }), 'GET');
    }

    const fetchFolder = async (folderId: string, includeFiles: boolean) => {
        try {
            return await apiRequest(`${API_PATH_FOLDERS}/${folderId}?` + new URLSearchParams({
                includeFiles: '' + includeFiles, spaceId: currentSpace.id
            }), 'GET');

        } catch (e) {
            console.error(e);

            if (e.message === 'Folder not found') {
                return APIError.NOT_FOUND;
            }
            if (e.message === 'Access denied') {
                return APIError.ACCESS_DENIED;
            }

            return null;

        }
    }

    const createFolder = async (folder: CreateFolderDto): Promise<FolderDto> => {
        return await apiRequest(`${API_PATH_FOLDERS}?` + new URLSearchParams({
            spaceId: currentSpace.id
        }), 'POST', folder);
    }

    const updateFolder = async (folder: UpdateFolderDto): Promise<FolderDto> => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(folder)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const getFolderAccessInfo = async (folderId: string) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}/access`,
                {
                    method: 'GET',
                    headers: await buildHeaders()
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;
        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
        }
    }

    const updateFolderAccess = async (folderId: string, request: UpdateFolderAccessDto) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}/access`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(request)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;
        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;
        }
    }

    const deleteFolder = async (folderId: string) => {
        return await apiRequest(`${API_PATH_FOLDERS}/${folderId}?` + new URLSearchParams({
            spaceId: currentSpace.id
        }), 'DELETE');
    }

    const deleteFile = async (fileId: string) => {
        return await apiRequest(`${API_PATH_FILES}/${fileId}?` + new URLSearchParams({
            spaceId: currentSpace.id
        }), 'DELETE');;
    }

    // TODO: Rename to uploadMetadata
    const uploadFiles = async (files: CreateFileDto[], folders: CreateFolderDto[]): Promise<FileMetadataUploadResponseDto> => {
        return await apiRequest(`${API_PATH_UPLOAD}?` + new URLSearchParams({
            spaceId: currentSpace.id
        }), 'POST', {
            files: files,
            folders: folders,
        })
    }

    const requestFilesUpload = async (filesUploadRequest: any) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FILES}/upload-request`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(filesUploadRequest)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while uploading data to the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            //navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const createUser = async (createUserDto: CreateUserDto): Promise<any> => {
        return await apiRequest(API_PATH_USERS, 'POST', createUserDto)
    }

    const login = async (email: string, password: string): Promise<boolean> => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)

            const idToken = await userCredential.user.getIdToken();
            if (idToken) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    // TODO: Deprecate
    const register = async (name: string, email: string, password: string) => {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        await sendEmailVerification(auth.currentUser)

        console.log('Email sent, user account created:', userCredential.user);

        openEmailVerificationModal(email);
    }

    return {
        fetchProfile,
        fetchFiles,
        fetchFolder,
        fetchFolders,
        fetchLocations,
        createLocation,
        updateLocation,
        updateFolder,
        createFolder,
        uploadFiles,
        searchFiles,
        requestFilesUpload,
        updateFolderAccess,
        getFolderAccessInfo,
        deleteFile,
        deleteFolder,
        login,
        register,
        loading,
        createUser,
    };
};

export default useApi;
