import { Stack } from "@mantine/core";
import React from "react";
import { signInWithGooglePopup } from "../../utils/FirebaseUtils";
import { GoogleButton } from "./GoogleButton";
//import { TwitterButton } from './TwitterButton';
import { UserCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AppleButton } from "./AppleButton";
import { FacebookButton } from "./FacebookButton";

export default function SocialLogin(props: { onLogin: any }) {
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const response: UserCredential = await signInWithGooglePopup();

    if (response) {
      props.onLogin();
    }
  };

  const renderSocialLoginButtons = () => {
    const isPrivateEnv = process.env.REACT_APP_ENVIRONMENT === "private";

    if (isPrivateEnv) {
      return (
        <Stack dir="" mb="md" mt="md">
          <GoogleButton radius="xl" onClick={signInWithGoogle}>
            Google
          </GoogleButton>
        </Stack>
      );
    }

    return (
      <Stack dir="" mb="md" mt="md">
        <GoogleButton radius="md" onClick={signInWithGoogle}>
          Google
        </GoogleButton>
        <AppleButton radius="md" onClick={signInWithGoogle}>
          Apple
        </AppleButton>
        <FacebookButton radius="md" onClick={signInWithGoogle}>
          Facebook
        </FacebookButton>
      </Stack>
    );
  };

  return renderSocialLoginButtons();
}
