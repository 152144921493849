import { Modal } from "@mantine/core";
import { FileDto } from '@braoshzmvavz/picnube-common';
import React from "react";
import { HiClock } from "react-icons/hi";
import { formatBytes, formatDate } from "../../utils/Utils";

export default function FileDetail(props: {
  opened: boolean;
  onClose: () => void;
  file: FileDto;
}) {
  if (!props.file) return <></>;

  return (
    <>
      <Modal.Root opened={props.opened} onClose={props.onClose}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{props.file.name}</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col gap-2">
              <img
                src={props.file.thumbnailUrl}
                alt={props.file.name}
                className="w-full h-full"
              ></img>
              <div className="flex flex-col justify-between items-start">
                <div className="flex flex-col">
                  <div className="text-lg">{props.file.name}</div>
                </div>
                <div className="flex flex-col">
                  <div className="text-lg">
                    {formatBytes(props.file.size)}
                  </div>
                  <div className="text-xs">{props.file.folderId}</div>
                </div>
                <div className="flex flex-col mt-4">
                  <div className="flex flex-row gap-2 items-center">
                    <HiClock />
                    <div className="text-lg">
                      {formatDate(props.file.dateCreated)}
                    </div>
                  </div>
                  {/*<div className="flex flex-row gap-2 items-center">
                    <HiLocationMarker />
                    <div className="text-lg">{"Tenerife"}</div>
                  </div>*/}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}
