import {
  Button,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { PLANS, PLANS_DATA } from '@braoshzmvavz/picnube-common';
import React, { useState } from "react";
import { CheckArrowIcon } from "./CheckArrowIcon";

export default function Step3(props: { onConfirm: any }) {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isMonthly, setIsMonthly] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = () => {
    setIsMonthly(!isMonthly);
  };

  const onCancel = () => {
    console.log("Cancelled");
  };

  const onConfirm = () => {
    console.log("Confirmed");
    props.onConfirm();
  };

  const mapCurrenctyToSymbol = (currency: string) => {
    switch (currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "JPY":
        return "¥";
      case "CAD":
        return "C$";
      case "AUD":
        return "A$";
      case "CHF":
        return "CHF";
      case "SEK":
        return "kr";
      default:
        return currency;
    }
  };

  const renderMonthlyYearlySwitch = () => {
    return (
      <div className="max-w-2xl mx-auto text-center mb-4">
        <label className="mx-auto bg-bgDark3 relative flex justify-between items-center group text-xl w-44 h-12 rounded-lg pr-36 pl-1 cursor-pointer">
          <input
            type="checkbox"
            className="peer appearance-none"
            checked={!isMonthly}
            onChange={handleChange}
          />
          <span className="h-8 w-[5.5rem] flex items-center pr-2 bg-bgDark3 after:rounded-lg duration-300 ease-in-out  after:w-[30rem] after:h-10  after:bg-primaryColor   after:shadow-md after:duration-300 peer-checked:after:translate-x-[5.5rem] cursor-pointer"></span>
          <div className="flex absolute text-primaryText text-sm font-bold">
            <div
              className={isMonthly ? "mr-9 ml-3" : "mr-9 ml-3 text-gray-400"}
            >
              Monthly
            </div>
            <div className={isMonthly ? "text-gray-400" : ""}>Yearly</div>
          </div>
        </label>
      </div>
    );
  };

  const beautifyNumber = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderBillingPlans = () => {
    const billingPlans = PLANS.map((plan, index) => {
      const planData = PLANS_DATA[plan];
      const planName = plan.charAt(0).toUpperCase() + plan.slice(1);
      return (
        <div className="flex-1 mb-8 lg:mb-0 ">
          <div className="p-4 md:p-8 bg-bgDark3 rounded-xl flex-1 md:max-w-[25rem]">
            <h3 className="text-xl font-bold font-heading text-primaryText text-left">
              {planName}
            </h3>
            <div className="flex justify-start items-end">
              <div className="text-3xl sm:text-5xl font-bold text-primaryText text-left mt-4 mr-2">
                {mapCurrenctyToSymbol(planData.prices[0].currency)}
                {planData.prices[0].amount}
              </div>
              <div className="text-gray-500 text-nowrap">
                {isMonthly ? "/ month" : "/ year"}
              </div>
            </div>
            <p className="mt-4 mb-6 2xl:mb-10 text-gray-500 text-left">
              {planData.description}
            </p>
            <ul className="mb-2 2xl:mb-6 text-primaryText">
              {planData.features.map((planFeature, index) => (
                <li className="mb-4 flex" key={`${planFeature.name}-${index}`}>
                  <CheckArrowIcon />
                  <span>{planFeature.description}</span>
                </li>
              ))}
            </ul>
            <ul className="mb-2 2xl:mb-6 text-primaryText">
              <li className="mb-4 flex" key={`${"storage"}-${index}`}>
                <CheckArrowIcon />
                <span>
                  {beautifyNumber(planData.limits.storage)} GB of storage
                </span>
              </li>
              <li className="mb-4 flex" key={`${"shares"}-${index}`}>
                <CheckArrowIcon />
                <span>{planData.limits.maxSpaces ?? "Unlimited"} spaces</span>
              </li>
            </ul>
            <div className="w-full flex justify-center items-center">
              <Button
                className="w-full rounded-xl font-bold mt-8 flex-1"
                onClick={() => setIsModalOpen(true)}
                aria-label="Get started"
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="flex flex-col xs:flex-row -mx-8 gap-2 sm:gap-4">
        {billingPlans}
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-between items-center h-full w-full">
      <div className="container mx-auto px-4 pt-4">
        {renderMonthlyYearlySwitch()}
        {renderBillingPlans()}
      </div>

      {/*  <Group justify="center" mt="xl">
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </Group> */}
    </div>
  );
}
