import { ActionIcon, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactCountryFlag from "react-country-flag";
import {
  HiOutlinePlus,
  HiOutlineX,
  HiPencil,
  HiTemplate,
} from "react-icons/hi";
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
} from "react-map-gl/maplibre";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useGlobalState, { globalProfile } from "../../globalState";
import { UpsertLocationDto } from "../../model/UpsertLocationDto";
import useApi from "../../services/useApi";
import Pin from "./Pin";
import {
  addLocation,
  updateLocationState,
} from "../../state/locations/locationSlice";
import { Location } from "../../model/Location";
import { CreateLocationDto, UpdateLocationDto } from '@braoshzmvavz/picnube-common';

// TODO: Animate a point along a path
// TODO: https://maplibre.org/maplibre-gl-js/docs/examples/animate-point-along-route/
export default function MapView(props: {
  defaultSelectedLocation?: string;
  onConfirm: (location: any) => void;
}) {
  const mapStyleJsons = {
    positron: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
    dark: "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json",
    voyager: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
    openStreet:
      "https://raw.githubusercontent.com/go2garret/maps/main/src/assets/json/openStreetMap.json",
    icgcDark:
      "https://geoserveis.icgc.cat/contextmaps/icgc_mapa_base_fosc.json",
  };

  const dispatch = useAppDispatch();
  const locationState = useAppSelector((state) => state.location);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { createLocation, updateLocation, fetchLocations, loading } = useApi();
  const profileState = useAppSelector((state) => state.profile);

  const mapRef = useRef<MapRef>(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);

  const [isAddLocationMode, setIsAddLocationMode] = useState(false);
  const [isEditLocationMode, setIsEditLocationMode] = useState(false);
  const [newLocationLatLong, setNewLocationLatLong] = useState(null);

  const [defaultLocationLatLong, setDefaultLocationLatLong] = useState(null);

  const form = useForm({
    mode: "controlled",
    initialValues: {
      name: selectedLocation?.name ?? "",
      // description: selectedLocation?.description ?? "",
    },

    validate: (values) => {
      return {
        name:
          values.name.trim().length < 2
            ? "Name must include at least 2 characters"
            : null,
      };
    },
  });

  useEffect(() => {
    if (!props.defaultSelectedLocation) return;

    const location: Location = locationState.locations.find(
      (location) => location.id === props.defaultSelectedLocation
    );
    if (!location) return;

    setSelectedLocation(location);
    setDefaultLocationLatLong([location.longitude, location.latitude]);
  }, [props.defaultSelectedLocation]);

  const pins = useMemo(
    () =>
      locationState.locations.map((mapPoint, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={mapPoint.longitude}
          latitude={mapPoint.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();

            if (
              !isAddLocationMode &&
              (!isEditLocationMode || selectedLocation?.id == mapPoint?.id)
            ) {
              onSelectMarker(mapPoint);
            }
          }}
        >
          <Pin
            selected={
              !(isAddLocationMode || isEditLocationMode) &&
              mapPoint?.name === selectedLocation?.name
            }
            light={isEditLocationMode && mapPoint?.id === selectedLocation?.id}
            color="gray"
            mapPoint={mapPoint}
          />
        </Marker>
      )),
    [selectedLocation, isAddLocationMode, isEditLocationMode]
  );

  const onMapViewportChange = () => {
    const map = mapRef.current;
    if (!map) return;

    const { lat, lng } = map.getCenter();
    setMapCenter([lat, lng]);
  };

  const onSelectMarker = useCallback((mapPoint) => {
    console.log(mapPoint);
    setSelectedLocation(mapPoint);
    const { latitude, longitude } = mapPoint;

    //mapRef.current?.panTo([longitude, latitude]);
    mapRef.current?.flyTo({ center: [longitude, latitude], duration: 1500 });
  }, []);

  const onSubmit = () => {
    if (isAddLocationMode) {
      // Create new location
      const location: CreateLocationDto = {
        name: form.values.name,
        latitude: mapCenter[0],
        longitude: mapCenter[1],
      };

      createLocation(location)
        .then((response: Location) => {
          console.log("createLocation", response);
          setIsAddLocationMode(false);
          dispatch(addLocation(response));
          if (response.id === selectedLocation.id) {
            setSelectedLocation(response);
          }
          props.onConfirm(response);
        })
        .catch((err) => {
          // TODO: show error
          console.error("createLocation", err);
        });
    } else if (isEditLocationMode) {
      const location: UpdateLocationDto = {
        id: selectedLocation.id,
        name: form.values.name,
        latitude: mapCenter[0],
        longitude: mapCenter[1],
      };

      console.log("isEditLocationMode", location);

      updateLocation(location)
        .then((response: Location) => {
          console.log("updateLocation", response);
          setIsEditLocationMode(false);
          dispatch(updateLocationState(response));
          setSelectedLocation(response);
          props.onConfirm(response);
        })
        .catch((err) => {
          // TODO: show error
          console.error("updateLocation", err);
        });
    } else {
      props.onConfirm(selectedLocation);
    }
  };

  const renderInfoArea = () => {
    if (isAddLocationMode || isEditLocationMode) {
      return (
        <div className="flex-1 flex flex-col px-4">
          <TextInput
            data-autofocus
            required
            label="Location name"
            className="flex-1"
            placeholder="Home"
            key={form.key("name")}
            {...form.getInputProps("name")}
          />
        </div>
      );
    }

    if (selectedLocation == null) {
      return (
        <div className="flex flex-col px-4 h-full items-center justify-center">
          <div className="text-sm text-grey-600">No location selected </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col px-4">
        <div className="">{selectedLocation?.name}</div>
        <div className="text-sm text-grey-600"></div>
        <div className="text-sm text-grey-600 flex flex-row gap-2 items-center mt-2">
          <ReactCountryFlag
            style={{ fontFamily: "Twemoji Country Flags" }}
            countryCode={selectedLocation?.countryCode}
          ></ReactCountryFlag>
          <div>
            {selectedLocation?.region}, {selectedLocation?.country}
          </div>
        </div>
      </div>
    );
  };

  const renderActionButtons = () => {
    const actionButtons = [];

    if (isAddLocationMode || isEditLocationMode) {
      actionButtons.push(
        <Button
          size="xs"
          variant="light"
          leftSection={<HiOutlineX />}
          onClick={() => {
            setIsAddLocationMode(false);
            setIsEditLocationMode(false);
            form.reset();
          }}
        >
          Cancel
        </Button>
      );
    }

    if (!isAddLocationMode && !isEditLocationMode) {
      actionButtons.push(
        <Button
          size="xs"
          variant="light"
          leftSection={<HiOutlinePlus />}
          onClick={() => setIsAddLocationMode(true)}
        >
          Add Location
        </Button>
      );
    }

    if (!isAddLocationMode && !isEditLocationMode && selectedLocation != null) {
      actionButtons.push(
        <Button
          size="xs"
          variant="light"
          leftSection={<HiPencil />}
          onClick={() => {
            form.setFieldValue("name", selectedLocation?.name);
            setIsEditLocationMode(true);
          }}
        >
          Edit Location
        </Button>
      );
    }

    return (
      <div className="w-full flex justify-end items-center gap-2">
        {actionButtons.map((actionButton, index) => (
          <>{actionButton}</>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full flex justify-between p-2">
        <div>
          <ActionIcon variant="light" onClick={() => null}>
            <HiTemplate />
          </ActionIcon>
        </div>
        {renderActionButtons()}
      </div>

      <Map
        id="mapData"
        onMove={onMapViewportChange}
        reuseMaps
        initialViewState={{
          longitude:
            defaultLocationLatLong != null
              ? defaultLocationLatLong[0]
              : locationState?.locations[0]?.longitude,
          latitude:
            defaultLocationLatLong != null
              ? defaultLocationLatLong[1]
              : locationState?.locations[0]?.latitude,
          zoom: 3,
        }}
        style={{ width: "100%", height: "100%" }}
        mapStyle={mapStyleJsons.positron}
        ref={mapRef}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl position="bottom-left" />

        {isAddLocationMode || isEditLocationMode ? (
          <Marker
            key={`marker-new-location`}
            draggable={false}
            onDrag={(e) => {
              //setNewLocationLatLong([e.lngLat.lat, e.lngLat.lng]);
            }}
            longitude={mapCenter[1]}
            latitude={mapCenter[0]}
            anchor="bottom"
            onClick={(e) => {
              // If we let the click event propagates to the map, it will immediately close the popup
              // with `closeOnClick: true`
              e.originalEvent.stopPropagation();
            }}
          >
            <Pin selected={true} />
          </Marker>
        ) : (
          <></>
        )}

        {pins}

        {/*popupInfo && (
          <Popup
            closeButton={false}
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
          >
            <div className="w-full h-full flex flex-col text-sm text-dark-600">
              <div className="flex justify-between items-center gap-2">
                {popupInfo.name}
                <CloseButton onClick={() => setPopupInfo(null)}></CloseButton>
              </div>
              <div className="text-xs mb-2">{popupInfo.date}</div>
              <div>
                <img width="100%" src={popupInfo.image} alt={popupInfo.name} />
              </div>
              <Button
                size="compact-sm"
                className="text-sm mt-2"
                onClick={() => navigate(`/browse/${popupInfo.folderId}`)}
              >
                Open
              </Button>
            </div>
          </Popup>
        )*/}
      </Map>

      <Group
        align="end"
        className="w-full flex"
        justify="space-between"
        mt="md"
        mb="md"
        pr={"md"}
      >
        {renderInfoArea()}

        <Button
          disabled={
            ((isAddLocationMode || isEditLocationMode) && !form.isValid()) ||
            (!isAddLocationMode &&
              !isEditLocationMode &&
              selectedLocation == null)
          }
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </Group>
    </div>
  );
}
