import {
  Button,
  LoadingOverlay,
  Modal,
  Overlay,
  ScrollArea,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useRef, useState } from "react";
import {
  HiArrowLeft,
  HiFolderAdd,
  HiLockClosed,
  HiOutlineCloudDownload,
  HiPencil,
} from "react-icons/hi";
import { RiDragDropLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import useApi from "../../services/useApi";
import { useAuth } from "../../services/useAuth";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import FileDetail from "../fileDetail/FileDetail";
import EditFolder from "../folder/EditFolder";
import Gallery from "../gallery/Gallery";
import { ActionId, MenuAction, MenuContext } from "../menu/menus";
import {
  openDeleteModal,
  openManageAccessModal,
  openRemoteControlConnectionModal,
  openShareModal,
} from "../modal/confirm";
import { getContentType } from "./ContentTypeUtils";
import FileDropzone from "./FileDropzone";

// State management
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addFiles,
  addFolders,
  incrementPage,
  resetToInitialState,
  selectSpaceRootFolders,
  setFiles,
  setFolders,
  setNewFolder,
  setSpaceRootFolders,
  updateFolderAction,
} from "../../state/explorer/explorerSlice";

import {
  CreateFolderDto,
  FileDto,
  FolderDto,
  FolderWithFiles,
  ProfileDto,
  UpdateFolderDto,
} from '@braoshzmvavz/picnube-common';
import { socket } from "../../services/socketService";
import useApiDemo from "../../services/useApiDemo";
import { selectCurrentSpace, setCurrentSpace, setProfile } from "../../state/profile/profileSlice";

export default function Loader(props: { demo?: boolean }) {
  const dispatch = useAppDispatch();
  const explorerState = useAppSelector((state) => state.explorer);
  const profileState = useAppSelector((state) => state.profile);
  const currentSpace = useAppSelector(selectCurrentSpace);

  const [isLoading, setIsLoading] = useState(true);
  //const [folder, setFolder] = useGlobalState<Folder>(globalFolder); // TODO: REMOVE
  //const [profile, setProfile] = useGlobalState<User>(globalProfile); // TODO: MOVE TO STATE
  const navigate = useNavigate();

  const [connectionToken, setConnectionToken] = useState(null);

  const [folderId, setFolderId] = useState(null);

  const currentSpaceRootFolders = useAppSelector(selectSpaceRootFolders);

  const [viewDetailFile, setViewDetailFile] = useState<FileDto>(null);

  const { auth, validateAuth } = useAuth();

  const {
    fetchFiles: fetchFilesReal,
    fetchFolder: fetchFolderReal,
    fetchFolders: fetchFoldersReal,
    fetchProfile: fetchProfileReal,
    updateFolder: updateFolderReal,
    createFolder: createFolderReal,
    updateFolderAccess: updateFolderAccessReal,
    getFolderAccessInfo: getFolderAccessInfoReal,
    deleteFile: deleteFileReal,
    deleteFolder: deleteFolderReal,
    loading: loadingReal,
  } = useApi();

  const {
    fetchFiles: fetchFilesDemo,
    fetchFolder: fetchFolderDemo,
    fetchFolders: fetchFoldersDemo,
    fetchProfile: fetchProfileDemo,
    updateFolder: updateFolderDemo,
    createFolder: createFolderDemo,
    updateFolderAccess: updateFolderAccessDemo,
    getFolderAccessInfo: getFolderAccessInfoDemo,
    deleteFile: deleteFileDemo,
    deleteFolder: deleteFolderDemo,
    loading: loadingDemo,
  } = useApiDemo();

  const location = useLocation();

  const [isNewFolderModalOpened, openCloseNewFolder] = useDisclosure(false);
  const openNewFolderModal = openCloseNewFolder["open"];
  const closeNewFolderModal = openCloseNewFolder["close"];

  const [isEditFolderModalOpened, openCloseEditFolder] = useDisclosure(false);
  const openEditFolderModal = openCloseEditFolder["open"];
  const closeEditFolderModal = openCloseEditFolder["close"];

  const [folderToEdit, setFolderToEdit] = useState<FolderDto | null>(null);

  const openRef = useRef<() => void>(null);

  const [isOverlay, setIsOverlay] = useState(false);
  const { pending, isSignedIn } = useAuth();

  const [isAccessDenied, setIsAccessDenied] = useState(false);

  const computedColorScheme = useComputedColorScheme("dark");
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isFileDetailOpened, { open: openFileDetail, close: closeFileDetail }] =
    useDisclosure(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openLightboxAndPlayFromFile, setOpenLightboxAndPlayFromFile] =
    useState<{ position?: number; file?: any; remoteControl?: boolean }>(null);

  // Map API calls to demo or real API
  const fetchFiles = () => (props.demo ? fetchFilesDemo : fetchFilesReal);
  const fetchFolder = () => (props.demo ? fetchFolderDemo : fetchFolderReal);
  const fetchFolders = () => (props.demo ? fetchFoldersDemo : fetchFoldersReal);
  const fetchProfile = () => (props.demo ? fetchProfileDemo : fetchProfileReal);
  const updateFolder = () => (props.demo ? updateFolderDemo : updateFolderReal);
  const createFolder = () => (props.demo ? createFolderDemo : createFolderReal);
  const updateFolderAccess = () =>
    props.demo ? updateFolderAccessDemo : updateFolderAccessReal;
  const getFolderAccessInfo = () =>
    props.demo ? getFolderAccessInfoDemo : getFolderAccessInfoReal;
  const deleteFile = () => (props.demo ? deleteFileDemo : deleteFileReal);
  const deleteFolder = () => (props.demo ? deleteFolderDemo : deleteFolderReal);
  const loading = () => (props.demo ? loadingDemo : loadingReal);

  useEffect(() => {
    document.title =
      explorerState.currentFolder?.name ?? process.env.REACT_APP_APPNAME;
  }, [explorerState.currentFolder]);

  useEffect(() => {
    if (folderId == null) return;

    // Check if state is already set
    if (explorerState.currentFolderId == folderId) {
      return;
    }

    setIsLoading(true);

    fetchFolder()(folderId, true).then((response: FolderWithFiles) => {
      if (response === null) {
        setIsAccessDenied(true);
        setIsLoading(false);
        return;
      }

      dispatch(setNewFolder(response));
      dispatch(setFiles(response.files));
      dispatch(setFolders(response.folders));

      setIsLoading(false);
    });
  }, [folderId]);

  useEffect(() => {
    if (location == null) return;
    if (props.demo) {
      getProfileAndParseLocation();
      return;
    }
    if (!pending && isSignedIn) getProfileAndParseLocation();
  }, [location, pending, isSignedIn]);

  const getProfileAndParseLocation = () => {
    setIsLoading(true);

    if (!!profileState.profile) {
      parseLocationWithProfileResolved(profileState.profile);
      return;
    }

    fetchProfile()().then((response: ProfileDto) => {
      if (response === null) {
        navigate("/login");
        return;
      }

      dispatch(setProfile(response));
      dispatch(setCurrentSpace(response.spaces[0]));

      parseLocationWithProfileResolved(response);
    });
  };

  const parseLocationWithProfileResolved = (profileData: any) => {
    let currentLocationPath = location.pathname;

    // Remove /demo from the path
    currentLocationPath = currentLocationPath.replace("/demo", "");

    if (currentLocationPath === "/browse") {
      setFolderId(null);

      dispatch(resetToInitialState());

      // Set space root folders
      if (
        currentSpaceRootFolders == null ||
        currentSpaceRootFolders.length == 0
      ) {
        fetchFolders()(profileState.currentSpace.id).then((response) => {
          if (response != null) {
            dispatch(setSpaceRootFolders(response));
            dispatch(setFolders(response));
            setIsLoading(false);
          }
        });
      } else {
        dispatch(setFolders(currentSpaceRootFolders));
        setIsLoading(false);
      }

      return;
    }

    dispatch(resetToInitialState());

    if (currentLocationPath.endsWith("/")) {
      currentLocationPath = currentLocationPath.substring(
        0,
        currentLocationPath.length - 1
      );
      navigate(currentLocationPath);
      return;
    }

    const pathNames = currentLocationPath.split("/").splice(2).reverse();
    const folderId = pathNames[0];

    setFolderId(folderId);

    setIsLoading(false);
  };

  const loadMore = () => {
    console.log("Load more triggered");
    if (isLoading) return;
    setIsLoading(true);
    fetchFiles()(currentSpace.id, explorerState.currentFolderId, explorerState.page + 1).then(
      (response: FileDto[]) => {
        dispatch(addFiles(response));

        // TODO: save data as global state to allow page changes
        setIsLoading(false);

        dispatch(incrementPage());
      }
    );
  };

  const onFolderClick = (folder: FolderDto) => {
    navigate((props.demo ? "/demo/browse/" : "/browse/") + folder.id);
  };

  const renderLoadMoreButton = () => {
    const hasNoFiles = explorerState.files.length == 0;
    const isFirstLoading = isLoading && explorerState.currentFolderId == null;
    const isLastPage = explorerState.files.length % 30 !== 0;

    if (isLastPage || hasNoFiles || isFirstLoading) {
      return <></>;
    }

    return (
      <div className="flex justify-center p-4">
        <Button
          onClick={loadMore}
          variant="default"
          leftSection={<HiOutlineCloudDownload />}
          loading={isLoading}
        >
          Load more
        </Button>
      </div>
    );
  };

  // Function to handle sharing the current photo
  const sharePhoto = async (photoUrl, photoName) => {
    try {
      // Fetch the image as a blob using the fetch API
      const response = await fetch(photoUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a File from the blob
      const file = new File([blob], photoName, {
        type: getContentType(photoName),
      });

      // Check if the browser supports the navigator share API
      if (navigator.share) {
        await navigator.share({
          title: "Check out this photo!",
          text: "I wanted to share this photo with you.",
          files: [file], // Sharing the file directly
        });
        console.log("Photo shared successfully!");
      } else {
        console.error("Web Share API not supported on this browser.");
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing photo:", error);
    }
  };

  const downloadPhoto = async (photoUrl, photoName) => {
    try {
      // Fetch the image as a blob using the fetch API
      const response = await fetch(photoUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = photoName; // Set the download attribute with the desired file name

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up: remove the link and revoke the blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);

      console.log("Photo downloaded successfully!");
    } catch (error) {
      console.error("Error downloading photo:", error);
    }
  };

  const onActionClick = (actionConfig: MenuAction, ctx: MenuContext) => {
    console.log("Loader.onActionClick", actionConfig, ctx);

    if (actionConfig.id === ActionId.SHARE) {
      if (!ctx.folder) return;
      openShareModal(
        ctx.folder,
        getFolderAccessInfo(),
        updateFolderAccess(),
        isMobile
      );
    }

    if (actionConfig.id === ActionId.SHARE_FILE) {
      if (!ctx.file) return;
      sharePhoto(ctx.file.url, ctx.file.name);
    }

    if (actionConfig.id === ActionId.OPEN_FILE_DETAIL) {
      if (!ctx.file) return;
      setViewDetailFile(ctx.file);
      openFileDetail();
    }

    if (actionConfig.id === ActionId.DOWNLOAD_FILE) {
      if (!ctx.file) return;
      downloadPhoto(ctx.file.url, ctx.file.name);
    }

    if (actionConfig.id === ActionId.MANAGE_ACCESS) {
      if (!ctx.folder) return;
      openManageAccessModal(
        ctx.folder,
        getFolderAccessInfo(),
        updateFolderAccess(),
        isMobile
      );
    }

    if (actionConfig.id === ActionId.UPLOAD_FILES) {
      openRef.current?.();
    }

    if (actionConfig.id === ActionId.DELETE_FILE) {
      if (!ctx.file) return;
      deleteFile()(ctx.file.id).then((response) => {
        notifications.show({
          id: "file-deleted",
          color: "green",
          title: "File deleted succesfully",
          message: "The file and its contents have been deleted",
          autoClose: false,
        });
        // If current folder has been deleted, navigate to the parent
        if (ctx.file.folderId === folderId) {
          if (!ctx.file.folderId) {
            dispatch(resetToInitialState());
            navigate(props.demo ? "/demo/browse" : "/browse");
          } else {
            navigate(
              props.demo ? "/demo/browse" : "/browse" + ctx.file.folderId
            );
          }
        } else {
          setFiles(
            explorerState.files.filter((f: FileDto) => f.id !== ctx.file.id)
          );
        }
      });
    }

    if (actionConfig.id === ActionId.VIEW_GALLERY) {
      if (!ctx.folder) return;
      navigate(`/gallery?folder=${ctx.folder.id}`);
    }

    // FIXME
    if (actionConfig.id === ActionId.UPDATE_FOLDER) {
      if (!ctx.folder) return;
      // openEditFolderModal(folder, profile.email);ç
      setFolderToEdit(ctx.folder);
      openEditFolderModal();
      //setFolder(folder); // instant local update for quick feedback
      // TODO: update in backend and handle error rollback
    }

    if (actionConfig.id === ActionId.DELETE_FOLDER) {
      if (!ctx.folder) return;
      openDeleteModal(isMobile, () => {
        deleteFolder()(ctx.folder.id).then((response) => {
          notifications.show({
            id: "folder-deleted",
            color: "green",
            title: "Folder deleted succesfully",
            message: "The folder and its contents have been deleted",
            autoClose: false,
          });
          // If current folder has been deleted, navigate to the parent
          if (ctx.folder.id === folderId) {
            if (!ctx.folder.parentId) {
              dispatch(resetToInitialState());
              navigate(props.demo ? "/demo/browse" : "/browse");
            } else {
              navigate(
                props.demo ? "/demo/browse" : "/browse" + ctx.folder.parentId
              );
            }
          } else {
            setFolders(
              explorerState.folders.filter(
                (f: FolderDto) => f.id !== ctx.folder.id
              )
            );
          }
        });
      });
    }

    // FIXME: Pass ctx.folder as the parent content where the new folder will be created
    if (actionConfig.id === ActionId.CREATE_FOLDER) {
      openNewFolderModal(); // Refactor as component or method
    }

    if (actionConfig.id === ActionId.PLAY) {
      if (!!ctx.file) {
        setOpenLightboxAndPlayFromFile({
          file: ctx.file,
          remoteControl: false,
        });
      }
      if (!!ctx.folder) {
        // TODO: change folder and then open lightbox if the folder is not the current folder
        setOpenLightboxAndPlayFromFile({ position: 0, remoteControl: false });
      }
    }

    if (actionConfig.id === ActionId.REMOTE_CONTROL) {
      // Show modal with QR code to establish connection from frontend B
      if (!ctx.folder) return;

      startRemoteControlSlideshow(ctx);
    }
  };

  const startRemoteControlSlideshow = (ctx: any) => {
    const remoteControlToken = crypto.randomUUID();

    openRemoteControlConnectionModal(isMobile, remoteControlToken, async () => {
      const token = await auth.currentUser.getIdToken();

      // setup socket connection
      socket.emit("frontend-a-init", {
        token: token,
        rcTocken: remoteControlToken,
      });

      // Open lightbox
      if (!!ctx.file) {
        setOpenLightboxAndPlayFromFile({
          file: ctx.file,
          remoteControl: true,
        });
      }
      if (!!ctx.folder) {
        // TODO: change folder and then open lightbox if the folder is not the current folder
        setOpenLightboxAndPlayFromFile({ position: 0, remoteControl: true });
      }
    });
  };

  const onNewFolderSubmit = (newFolder: CreateFolderDto) => {
    createFolder()(newFolder).then((response: FolderDto) => {
      setFolders(
        [...explorerState.folders, response].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      );

      closeNewFolderModal();
    });
  };

  const onEditFolderSubmit = (updatedFolder: UpdateFolderDto) => {
    setFolderToEdit(null);

    updateFolder()(updatedFolder).then((response: FolderDto) => {
      dispatch(updateFolderAction(response));

      // TODO: review
      //setFolders(response.folders);

      // Update profile folders
      /*if (!updatedFolder.parentId) {
        setProfile({
          ...profile,
          folders: profile.folders.map((f: Folder) =>
            f.id === updatedFolder.id ? response : f
          ),
        });
      }*/

      closeEditFolderModal();
    });
  };

  const onFilesUploaded = (
    uploadedFiles: FileDto[],
    newFolders: CreateFolderDto[]
  ) => {
    const updatedFiles = [
      ...explorerState.files,
      ...uploadedFiles.filter(
        (f) => f.folderId === explorerState.currentFolderId
      ),
    ];
    const updatedFolders = [
      ...explorerState.folders,
      ...newFolders.filter(
        (f) => f.parentFolderId === explorerState.currentFolderId
      ),
    ];
    dispatch(setFiles(updatedFiles));
    dispatch(setFolders(updatedFolders as any)); // TODO: Test

    console.log("Uploaded files to load in Gallery: ", uploadedFiles);
    // TODO: sort files

    notifications.show({
      id: "upload-complete",
      color: "green",
      title: "Upload complete",
      message: "Your files were uploaded succesfully",
      autoClose: false,
    });
  };

  // TODO: move to breadcrumb
  const onBreadcrumbNavigate = () => {};

  // TODO: move to breadcrumb
  const onBackClick = () => {
    setIsAccessDenied(false);

    navigate(-1);
  };

  // TODO: move to breadcrumb
  const onHomeClick = () => {
    setIsAccessDenied(false);
    setIsLoading(true);

    dispatch(resetToInitialState());
    navigate(props.demo ? "/demo/browse" : "/browse");
  };

  const renderEmptyFolderPlaceholder = () => {
    if (isLoading) return <></>;
    if (explorerState.files?.length != 0) return <></>;
    if (explorerState.folders?.length != 0) return <></>;

    const msg = "Drag and drop files or click to upload";

    return (
      <div
        className="w-full h-full absolute flex flex-col gap-2 items-center justify-center text-center p-4
      cursor-pointer select-none text-gray-500 hover:text-blue-400 active:text-blue-500"
        onClick={() => openRef.current?.()}
      >
        <RiDragDropLine className="text-5xl" />
        <div className="text-lg mt-4">{msg}</div>
      </div>
    );
  };

  // Handle access denied error
  if (!isLoading && isAccessDenied) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center text-center p-4">
        <HiLockClosed className="text-5xl mb-3"></HiLockClosed>
        <div className="text-2xl">Access denied</div>
        <div className="text-lg mt-4">
          You don't have permission to access this folder
        </div>
        <div className="flex justify-between gap-2">
          <Button
            onClick={() => onBackClick()}
            variant="default"
            className="mt-4"
          >
            <HiArrowLeft className="mr-2"></HiArrowLeft>
            Go back
          </Button>
          <Button
            onClick={() => onHomeClick()}
            variant="subtle"
            className="mt-4"
          >
            Home
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full items-center justify-center relative">
      {isOverlay && <Overlay color="#000" backgroundOpacity={0.45} blur={1} />}

      <FileDropzone onFilesUploaded={onFilesUploaded} openRef={openRef} />

      <Breadcrumb
        onActionClick={onActionClick}
        setIsLoaderOverlay={setIsOverlay}
        onNavigate={onBreadcrumbNavigate}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        demo={props.demo}
      ></Breadcrumb>

      <ScrollArea
        scrollbarSize={14}
        scrollHideDelay={1500}
        type="hover"
        className="w-full h-full relative"
      >
        <LoadingOverlay
          visible={explorerState.files.length == 0 && isLoading}
          zIndex={400}
          overlayProps={{
            blur: "xl",
            color: computedColorScheme === "dark" ? "black" : "black",
            opacity: computedColorScheme === "dark" ? "70%" : "30%",
          }}
          loaderProps={{ color: "blue", type: "bars", size: "xl" }}
        />
        {explorerState.files.length == 0 &&
          isLoading &&
          renderEmptyFolderPlaceholder()}
        <div
          className={
            "flex flex-col pb-2 overflow-auto w-full" +
            (isMobile ? "" : " pt-14")
          }
        >
          <Gallery
            folders={explorerState.folders}
            files={explorerState.files}
            loadMoreFn={loadMore}
            onFolderClickFn={onFolderClick}
            onActionClick={onActionClick}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            openLightboxAndPlayFromFile={openLightboxAndPlayFromFile}
            setOpenLightboxAndPlayFromFile={setOpenLightboxAndPlayFromFile}
            demo={props.demo}
          ></Gallery>
          {renderLoadMoreButton()}
        </div>
      </ScrollArea>

      <Modal
        opened={isNewFolderModalOpened}
        onClose={closeNewFolderModal}
        closeOnClickOutside={!loading}
        closeOnEscape={!loading}
        title={
          <div className="flex flex-row gap-2">
            <HiFolderAdd />
            <div>New Folder</div>
          </div>
        }
      >
        <EditFolder
          folder={null}
          parentFolder={explorerState.currentFolder}
          onSubmit={onNewFolderSubmit}
          loading={loading}
        ></EditFolder>
      </Modal>

      <Modal
        opened={isEditFolderModalOpened}
        onClose={closeEditFolderModal}
        closeOnClickOutside={!loading}
        closeOnEscape={!loading}
        title={
          <div className="flex flex-row gap-2">
            <HiPencil />
            <div>Edit Folder</div>
          </div>
        }
      >
        <EditFolder
          folder={folderToEdit ?? explorerState.currentFolder}
          parentFolder={explorerState.currentFolder}
          onSubmit={onEditFolderSubmit}
          loading={loading}
        ></EditFolder>
      </Modal>

      <FileDetail
        opened={isFileDetailOpened}
        file={viewDetailFile}
        onClose={closeFileDetail}
      ></FileDetail>
    </div>
  );
}
