import {
  Button,
  Group,
  PasswordInput,
  Text,
  TextInput,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  updateProfile,
  User,
} from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../services/useApi";
import SocialLogin from "../auth/SocialLogin";

export default function Step1(props: {
  onConfirm: (userCredential: User) => void;
  onCancel: () => void;
}) {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const navigate = useNavigate();
  const computedColorScheme = useComputedColorScheme("dark");

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const onCancel = () => {
    navigate("/login");
  };

  const onConfirm = async () => {
    console.log("Confirmed");
    if (form.validate().hasErrors) {
      return;
    }

    const name = form.values.name;
    const email = form.values.email;
    const password = form.values.password;

    createUserWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        console.log(userCredential);

        // Update Firebase profile with name
        updateProfile(userCredential.user, {
          displayName: name,
        }).then(() => {
          props.onConfirm(userCredential.user);
        });
      })
      .catch((error: FirebaseError) => {
        console.log(error, getAuth().currentUser);
        if (error.code === "auth/email-already-in-use") {
          // Try to login with email and password
          signInWithEmailAndPassword(getAuth(), email, password)
            .then((userCredential) => {
              const user = userCredential.user;
              console.log(userCredential);
              props.onConfirm(user);
              return;
            })
            .catch((error: FirebaseError) => {
              form.setFieldError("email", "Email already in use");
              return;
            });
        } else if (error.code === "auth/invalid-email") {
          form.setFieldError("email", "Invalid email");
          return;
        } else {
          notifications.show({
            id: "error-unknown",
            color: "red",
            title: "Error while creating account",
            message: "Check your internet connection",
            autoClose: false,
          });
        }
        //navigate("/login");
      });
  };

  const onLogin = () => {
    console.log("Social login");
    const currentUser = getAuth().currentUser;

    props.onConfirm(currentUser);
  };

  const form = useForm({
    // mode: "uncontrolled",
    initialValues: {
      password: "",
      name: "",
      email: "",
    },

    validate: (values) => {
      return {
        name:
          values.name.trim().length < 2
            ? "Name must include at least 2 characters"
            : null,
        email: /^\S+@\S+$/.test(values.email) ? null : "Invalid email",
        password:
          values.password.length < 6
            ? "Password must include at least 6 characters"
            : null,
      };
    },
  });

  return (
    <div className="flex flex-col justify-between items-center h-full w-full max-w-[40rem]">
      <form
        className="flex flex-col w-full h-full pt-4"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* <h1 className="text-2xl mb-2">Sign up with</h1> */}
        <Text size="md" fw={500}>
          Sign up with
        </Text>
        <SocialLogin onLogin={onLogin} />

        {/* <h1 className="text-2xl mt-6 mb-2">Or create an account</h1> */}
        <Text size="md" fw={500} mt="md">
          Or create an account
        </Text>
        <div className="flex flex-wrap w-full min-w-10 gap-x-4">
          <TextInput
            className="flex-1 min-w-60"
            mt="md"
            label="Name"
            placeholder="Name"
            key={form.key("name")}
            {...form.getInputProps("name")}
            onChange={(event) =>
              form.setFieldValue("name", event.currentTarget.value)
            }
          />
          <TextInput
            className="flex-1 min-w-60"
            mt="md"
            label="Email"
            placeholder="Email"
            key={form.key("email")}
            {...form.getInputProps("email")}
            onChange={(event) =>
              form.setFieldValue("email", event.currentTarget.value)
            }
          />
        </div>

        <PasswordInput
          mt="md"
          label="Password"
          placeholder="Password"
          key={form.key("password")}
          {...form.getInputProps("password")}
          onChange={(event) =>
            form.setFieldValue("password", event.currentTarget.value)
          }
        />
      </form>

      <Group justify="center" mt="xl">
        <Button variant="transparent" onClick={onCancel}>
          Already have an account? Return to login
        </Button>
        <Button onClick={() => onConfirm().then()}>Confirm</Button>
      </Group>
    </div>
  );
}
