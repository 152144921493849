import { FolderDto } from '@braoshzmvavz/picnube-common';

export const getFolderUrl = (folder: FolderDto) => {
    if (!folder?.id) return;
    return `${window.location.origin}/browse/${folder.id}`;
};

export const getRemoteControlUrl = (token: string) => {
    return `${window.location.origin}/remote?token=${token}`;
}
