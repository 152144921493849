import { FileDto, FolderDto, FolderWithFiles } from '@braoshzmvavz/picnube-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

// Define a type for the slice state
interface ExplorerState {
    currentFolderId: string;
    currentFolder: FolderDto;
    folders: FolderDto[];
    spaceFolders: FolderDto[];
    files: FileDto[];
    page: number;
}

// Define the initial state using that type
// Initial value in explorer is used in /browse
const initialState: ExplorerState = {
    currentFolderId: null,
    currentFolder: null,
    folders: [],
    spaceFolders: [],
    files: [],
    page: 0,
}

export const explorerSlice = createSlice({
    name: 'explorer',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetToInitialState: state => {
            state.currentFolderId = null
            state.currentFolder = null
            state.folders = []
            //state.spaceFolders = []
            state.files = []
            state.page = 0
        },

        setNewFolder: (state, action: PayloadAction<FolderWithFiles>) => {
            if (action.payload == null) {
                state.currentFolder = null
                state.currentFolderId = null
                state.page = 0
                state.files = []
                state.folders = []
                return;
            }
            state.currentFolder = action.payload
            state.currentFolderId = action.payload.id
            state.page = 0
            state.files = action.payload.files
            state.folders = action.payload.folders
        },

        addFiles: (state, action: PayloadAction<FileDto[]>) => {
            state.files = [...state.files, ...action.payload]
        },

        setFiles: (state, action: PayloadAction<FileDto[]>) => {
            state.files = action.payload
        },

        addFolders: (state, action: PayloadAction<FolderDto[]>) => {
            state.folders = [...state.folders, ...action.payload]
        },

        setFolders: (state, action: PayloadAction<FolderDto[]>) => {
            state.folders = action.payload
        },

        setSpaceRootFolders: (state, action: PayloadAction<FolderDto[]>) => {
            state.spaceFolders = action.payload
        },

        updateFolderAction: (state, action: PayloadAction<FolderDto>) => {
            state.folders = state.folders.map((folder) => {
                if (folder.id === action.payload.id) {
                    return action.payload;
                }
                return folder;
            });
            if (state.currentFolderId === action.payload.id) {
                state.currentFolder = action.payload;
            }
        },

        createFolderAction: (state, action: PayloadAction<FolderDto>) => {
            // TODO: review
            state.folders = [...state.folders, action.payload]
            state.currentFolder = action.payload
            state.currentFolderId = action.payload.id
            state.page = 0
            //state.files = action.payload.files // TODO
            //state.folders = action.payload.folders // TODO
        },

        incrementPage: (state) => {
            state.page += 1
        },
    }
})

export const {
    resetToInitialState,
    setNewFolder,
    addFiles,
    setFiles,
    addFolders,
    setFolders,
    setSpaceRootFolders,
    updateFolderAction,
    createFolderAction,
    incrementPage,
} = explorerSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentFolder = (state: RootState) => state.explorer.currentFolder
export const selectCurrentFolderSubfolders = (state: RootState) => state.explorer.folders
export const selectSpaceRootFolders = (state: RootState) => state.explorer.spaceFolders

export default explorerSlice.reducer