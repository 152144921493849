import {
  ActionIcon,
  Button,
  Divider,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import {
  HiArrowRight,
  HiCheck,
  HiClipboardCopy,
  HiFolder,
  HiUsers,
} from "react-icons/hi";
import QRCode from "react-qr-code";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { getFolderUrl } from "../../utils/UrlUtils";
import { openManageAccessModal } from "./confirm";
import { useForm } from "@mantine/form";
import { UpdateFolderAccessDto } from "../../model/UpdateFolderAccessDto";
import useGlobalState, { globalProfile } from "../../globalState";
import { useAppSelector } from "../../app/hooks";
import { FolderDto } from '@braoshzmvavz/picnube-common';

export default function ShareModal(props: {
  folder: FolderDto;
  getFolderAccessInfo: (folderId: string) => Promise<any>;
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>;
}) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const profileState = useAppSelector((state) => state.profile);
  const [copied, setCopied] = useState(false);

  const renderManageAccessButton = (folder: FolderDto) => {
    //if (folder.accessLevel !== "write") return null;

    return (
      <>
        <Divider className="mt-3"></Divider>
        <div className="flex flex-col gap-4 mt-2">
          <Button
            className="flex w-full"
            variant="filled"
            leftSection={<HiUsers />}
            onClick={() =>
              openManageAccessModal(
                props.folder,
                props.getFolderAccessInfo,
                props.updateFolderAccess,
                isMobile
              )
            }
          >
            Manage access
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-2 w-full p-4 overflow-auto">
      <div className="flex flex-row gap-2 justify-center items-center text-2xl mt-2">
        <HiFolder />
        {props.folder?.name}
      </div>

      <div className="max-w-52 m-auto mb-2 w-full h-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center  p-3 bg-white">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={getFolderUrl(props?.folder)}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <div className="flex flex-row justify-start items-center">
        <Text className="text-center text-xs">Share with</Text>
      </div>

      <div className="flex flex-row justify-start items-center gap-3 flex-wrap">
        <WhatsappShareButton url={getFolderUrl(props?.folder)}>
          <WhatsappIcon size={38} />
        </WhatsappShareButton>
        <TelegramShareButton url={getFolderUrl(props?.folder)}>
          <TelegramIcon size={38} />
        </TelegramShareButton>
        <FacebookShareButton url={getFolderUrl(props?.folder)}>
          <FacebookIcon size={38} />
        </FacebookShareButton>
        <TwitterShareButton url={getFolderUrl(props?.folder)}>
          <TwitterIcon size={38} />
        </TwitterShareButton>
        <EmailShareButton url={getFolderUrl(props?.folder)}>
          <EmailIcon size={38} />
        </EmailShareButton>
      </div>

      <div className="flex flex-row justify-start items-center mt-2">
        <Text className="text-center text-xs">Or share the link</Text>
      </div>

      <div className="flex flex-row gap-3 w-full justify-between items-center">
        <TextInput className="flex-1" value={getFolderUrl(props?.folder)} />
        <div className="flex flex-row justify-center items-center h-full">
          <Tooltip
            label={copied ? "Copied" : "Copy"}
            withArrow
            position="right"
          >
            <ActionIcon
              className="flex "
              color={copied ? "teal" : "gray"}
              variant="subtle"
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(getFolderUrl(props?.folder));
              }}
            >
              {copied ? <HiCheck /> : <HiClipboardCopy />}
            </ActionIcon>
          </Tooltip>
        </div>
      </div>

      {renderManageAccessButton(props?.folder)}
    </div>
  );
}
