export function replaceGlobally(original: string, searchTxt: string, replaceTxt: string): string {
    const regex = new RegExp(searchTxt, 'g');
    return original.replace(regex, replaceTxt);
}

export function stringToBgAndTextColors(str: string): { backgroundColor: string; textColor: string } {
    // Hash function to generate a number from the string
    let hash = 1234567;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert hash to a hex color
    let color = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 255;
        color += value.toString(16).padStart(2, "0");
    }

    // Calculate luminance to determine text color (black or white)
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose text color based on luminance (light colors get black text, dark colors get white)
    const textColor = luminance > 0.5 ? "#000000" : "#FFFFFF";

    return { backgroundColor: color, textColor };
}
