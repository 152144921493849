import {
  Button,
  Stepper,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { getAuth, User } from "firebase/auth";
import React, { useRef, useState } from "react";
import { HiCheck, HiMail, HiUser } from "react-icons/hi";
import { HiBolt } from "react-icons/hi2";
import useApi from "src/services/useApi";
import "./Onboarding.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import StepWrapper from "./StepWrapper";

export default function Onboarding() {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const { createUser } = useApi();

  const isUserCreated = useRef(false);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [active, setActive] = useState(0);

  const createUserInDatabase = async () => {
    const currentUser = getAuth().currentUser;

    // Create user in DB
    const userDto = await createUser({
      name: currentUser.displayName,
      email: currentUser.email,
    });

    console.log("Created user", userDto);
  };

  const createUserAndGoToNextStep = async () => {
    createUserInDatabase()
      .then(() => {
        isUserCreated.current = true;
        nextStep();
      })
      .catch((e) => {
        // User is already created in database
        if (e?.message === "Email already in use") {
          navigateHome()();
          return;
        }

        console.error(e);
        showNotification({
          id: "error-unknown",
          color: "red",
          title: "Error while creating user",
          message: e?.message ?? "Check your internet connection",
          autoClose: false,
        });
      });
  };

  let STEPS = [
    {
      description: "Create an account",
      label: "Account",
      icon: <HiUser />,
      content: (
        <Step1
          onConfirm={(user: User) => {
            if (!user?.emailVerified) {
              console.log("Email not verified");
              nextStep();
              return;
            }

            createUserAndGoToNextStep();
          }}
          onCancel={() => {}}
        />
      ),
    },
    {
      description: "Verify email",
      label: "Security",
      icon: <HiMail />,
      content: (
        <Step2
          onConfirm={() => {
            if (isUserCreated.current) {
              nextStep();
              return;
            }
            createUserAndGoToNextStep();
          }}
          onCancel={() => {
            setActive(0);
          }}
        />
      ),
    },
    {
      description: "Select billing plan",
      label: "Billing",
      icon: <HiBolt />,
      content: (
        <Step3
          onConfirm={() => {
            nextStep();
            console.log("Step 3 completed: billing info added");
          }}
        />
      ),
    },
  ];

  // Remove last step if private environment
  if (process.env.REACT_APP_ENVIRONMENT === "private") {
    STEPS = STEPS.slice(0, -1);
  }

  const nextStep = () => {
    setActive((current) => (current < STEPS.length ? current + 1 : current));
  };

  const navigateHome = () => {
    return () => {
      window.location.href = "/";
    };
  };

  return (
    <div className="flex flex-col justify-between items-center h-full w-full">
      <Stepper
        className="w-full h-full p-8 m-auto 
        flex flex-col"
        orientation={"horizontal"}
        active={active}
        /*onStepClick={setActive}*/
        allowNextStepsSelect={false}
      >
        {STEPS.map((step, index) => (
          <Stepper.Step
            key={index}
            icon={step.icon}
            label={isMobile ? "" : step.label}
            description={isMobile ? "" : step.description}
            completedIcon={<HiCheck />}
            onClick={() => setActive(index)}
          >
            <StepWrapper>{step.content}</StepWrapper>
          </Stepper.Step>
        ))}

        <Stepper.Completed>
          <div className="w-full h-full flex flex-col justify-center items-center gap-8">
            <div className="text-3xl">
              Welcome to {process.env.REACT_APP_APPNAME}! 🎉
            </div>
            <Button variant="filled" size="md" onClick={navigateHome()}>
              Start
            </Button>
          </div>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
}
