import {
  Button,
  CloseButton,
  Combobox,
  Group,
  Modal,
  SegmentedControl,
  Textarea,
  TextInput,
  useCombobox,
  useMantineTheme,
} from "@mantine/core";
import {
  DatePickerInput,
  MonthPickerInput,
  YearPickerInput,
} from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  CreateFolderDto,
  FolderDto,
  ProfileDto,
  UpdateFolderDto,
} from '@braoshzmvavz/picnube-common';
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { HiLocationMarker, HiMap } from "react-icons/hi";
import { useAppSelector } from "../../app/hooks";
import MapView from "../mapView/MapView";
import "./EditFolder.css";

const initializeUpdatedFolder = (
  folder: FolderDto,
  parentFolder: FolderDto,
  profile: ProfileDto
) => {
  if (!!folder) return folder;

  const uuid = crypto.randomUUID();

  // 1st level folder (no parent)
  if (!parentFolder) {
    return {
      id: uuid,
      parentId: null,
      name: "",
      owner: profile.email,
      readUsers: [profile.email],
      writeUsers: [profile.email],
      dateCreated: new Date(),
      folders: [],
      path: "",
    };
  }

  // 2nd level folder
  return {
    id: uuid,
    parentId: parentFolder?.id,
    name: "",
    //owner: parentFolder?.owner, // TODO: add ownerEmail
    dateCreated: new Date(),
    folders: [],
    path: parentFolder?.path + `;${parentFolder?.id}:${parentFolder?.name}`,
  };
};

export default function EditFolder({
  folder,
  parentFolder,
  onSubmit,
  loading,
}: {
  folder: FolderDto;
  parentFolder: FolderDto;
  onSubmit: any;
  loading: any;
}) {
  const profile = useAppSelector((state) => state.profile.profile);
  const currentSpace = useAppSelector((state) => state.profile.currentSpace);

  const [updatedFolder, setUpdatedFolder] = useState(
    initializeUpdatedFolder(folder, parentFolder, profile)
  );
  const [dateMode, setDateMode] = useState("Day");

  const locationState = useAppSelector((state) => state.location);

  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const [isEditLocationModalOpened, openCloseEditLocation] =
    useDisclosure(false);
  const openEditLocationModal = openCloseEditLocation["open"];
  const closeEditLocationModal = openCloseEditLocation["close"];

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [comboboxLocation, setComboboxLocation] = useState<string | null>(
    locationState.locations.filter(
      (location) => location.id === folder?.locationId
    )[0]?.name ?? null
  );
  // Location combobox
  const locationOptions = locationState.locations
    .filter((item) => {
      if (!comboboxLocation) return true;
      return item.name
        .toLowerCase()
        .includes(comboboxLocation.toLowerCase().trim());
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => (
      <Combobox.Option value={item.name} key={item.id}>
        <div className="flex flex-row justify-start items-center gap-2">
          <ReactCountryFlag
            style={{ fontFamily: "Twemoji Country Flags" }}
            countryCode={item?.countryCode}
          ></ReactCountryFlag>
          {item.name}
        </div>
      </Combobox.Option>
    ));
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const updatePath = (newFolderName: string, folderId: string) => {
    let path = `${folderId}:${newFolderName}`;

    // Add parent folder path to the path if parent folder is not null
    if (!!parentFolder?.path) {
      return `${parentFolder.path};${path}`;
    }

    // Add current folder path to the path if current folder is not null removing last ;
    if (!!folder?.path) {
      return `${folder.path.replace(`${folderId}:${folder.name}`, "")}${path}`;
    }

    return path;
  };

  useEffect(() => {
    if (!!folder) {
      console.log("FOLDER", folder);
      form.setFieldValue("name", folder.name);
      form.setFieldValue("description", folder.description);
      if (!!folder?.dateValue && !!folder?.dateType) {
        setDateMode(folder.dateType);
        form.setFieldValue("date", new Date(folder.dateValue));
      }

      if (!!folder?.locationId) {
        const location = locationState.locations.find(
          (loc) => loc.id === folder.locationId
        );
        console.log("locationId", folder.locationId);
        console.log("location", location);
        setComboboxLocation(location.name);
      }
    }
  }, [folder]);

  useEffect(() => {
    if (!!comboboxLocation) {
      const location = locationState.locations.find(
        (location) =>
          location.name.toLowerCase() === comboboxLocation.toLowerCase().trim()
      );
      form.setFieldValue("locationId", location?.id);
    }
  }, [comboboxLocation]);

  const onSaveClick = () => {
    // TODO: add validation for conflicting name with another folder
    if (form.validate().hasErrors || !updatedFolder) {
      return;
    }

    const date =
      form.values.date == null
        ? null
        : {
            type: dateMode as any,
            value: form.values.date.toISOString(),
          };

    // Set location
    let locationId = null;
    if (!!comboboxLocation) {
      const location = locationState.locations.find(
        (location) =>
          location.name.toLowerCase() === comboboxLocation.toLowerCase().trim()
      );
      form.setFieldValue("locationId", location.id);
      locationId = location.id;
    }

    // If folder exists, update it
    if (!!folder) {
      const data: UpdateFolderDto = {
        id: folder.id,
        parentFolderId: folder.parentId,
        name: form.values.name,
        path: updatePath(form.values.name, folder.id),
        description: form.values.description,
        dateValue: !!date ? date.value : undefined,
        dateType: !!date ? date.type : undefined,
        locationId: locationId,
      };

      console.log("Upsert Folder", data);
      onSubmit(data);
      return;
    }

    // If folder does not exist, create it
    const uuid = crypto.randomUUID();
    const data: CreateFolderDto = {
      id: uuid,
      spaceId: currentSpace.id,
      parentFolderId: folder?.parentId ?? null,
      name: form.values.name,
      path: updatePath(form.values.name, uuid),
      description: form.values.description,
      dateValue: !!date ? date.value : undefined,
      dateType: !!date ? date.type : undefined,
      locationId: locationId,
    };

    console.log("Create Folder", data);
    onSubmit(data);
  };

  const renderManageFolderAccess = () => {
    if (!!folder) return <></>;

    return <></>;
    //return <ManageFolderAccess folder={updatedFolder}></ManageFolderAccess>;
  };

  const form = useForm({
    mode: "controlled",
    initialValues: {
      name: folder?.name ?? "",
      description: folder?.description ?? "",
      date: !!folder?.dateValue ? new Date(folder.dateValue) : null,
      locationId: folder?.locationId ?? null,
    },

    validate: (values) => {
      return {
        name:
          values.name.trim().length < 2
            ? "Name must include at least 2 characters"
            : null,
      };
    },
  });

  {
    /*<ScrollArea
      offsetScrollbars
      scrollbarSize={18}
      scrollHideDelay={1500}
      type="auto"
      className="flex-1 pb-[var(--mantine-spacing-md)]"
    >*/
  }

  const renderDateSelector = () => {
    if (dateMode === "Day") {
      return (
        <DatePickerInput
          className="flex-1"
          mt="md"
          clearable
          label={
            <div className="flex flex-row gap-2 w-full items-end justify-between">
              <div>Date</div>
              <SegmentedControl
                size="xs"
                data={["Year", "Month", "Day"]}
                defaultValue="Day"
                onChange={(value) => setDateMode(value)}
              />
            </div>
          }
          placeholder="Select day"
          highlightToday
          key={form.key("date")}
          {...form.getInputProps("date")}
        />
      );
    }

    if (dateMode === "Month") {
      return (
        <MonthPickerInput
          className="flex-1"
          mt="md"
          clearable
          value={form?.values?.date ?? null}
          label={
            <div className="flex flex-row gap-2 w-full items-end justify-between">
              <div>Date</div>
              <SegmentedControl
                size="xs"
                data={["Year", "Month", "Day"]}
                defaultValue="Month"
                onChange={(value) => setDateMode(value)}
              />
            </div>
          }
          placeholder="Select month"
          key={form.key("date")}
          {...form.getInputProps("date")}
        />
      );
    }

    if (dateMode === "Year") {
      return (
        <YearPickerInput
          className="flex-1"
          mt="md"
          clearable
          value={form?.values?.date ?? null}
          label={
            <div className="flex flex-row gap-2 w-full items-end justify-between">
              <div>Date</div>
              <SegmentedControl
                size="xs"
                data={["Year", "Month", "Day"]}
                defaultValue="Year"
                onChange={(value) => setDateMode(value)}
              />
            </div>
          }
          placeholder="Select year"
          key={form.key("date")}
          {...form.getInputProps("date")}
        />
      );
    }
  };

  return (
    <div className="px-[var(--mantine-spacing-md)] p-0 w-full h-full flex flex-col">
      <div className="flex flex-wrap w-full min-w-10 gap-x-4">
        <TextInput
          data-autofocus
          required
          className="flex-1 min-w-60"
          mt="md"
          label="Name"
          placeholder="Folder name"
          key={form.key("name")}
          {...form.getInputProps("name")}
        />

        <Textarea
          className="w-full"
          mt="md"
          label="Description"
          placeholder="Add a description"
          resize="vertical"
          key={form.key("description")}
          {...form.getInputProps("description")}
        ></Textarea>

        <div className="flex-1 min-w-60 flex flex-row gap-2 items-end date-selector">
          {renderDateSelector()}
          {/*<Button
            className="min-w-fit"
            size="sm"
            variant="light"
            onClick={() => {
              form.setFieldValue("date", new Date());
            }}
            leftSection={<HiMiniClock />}
          >
            Today
          </Button>*/}
        </div>

        <div className="w-full h-full mt-4 flex flex-row items-end gap-2 flex-wrap">
          <Combobox
            onOptionSubmit={(optionValue) => {
              console.log("location combobox onOptionSubmit", optionValue);
              setComboboxLocation(optionValue);
              combobox.closeDropdown();
            }}
            store={combobox}
          >
            <Combobox.Target>
              <TextInput
                className="flex-1 min-w-60"
                label="Location"
                placeholder="Search saved location"
                value={comboboxLocation !== null ? comboboxLocation : ""}
                onChange={(event) => {
                  setComboboxLocation(event.currentTarget.value);
                  //setSearch(event.currentTarget.value);
                  combobox.openDropdown();
                  combobox.updateSelectedOptionIndex();
                }}
                onClick={() => combobox.openDropdown()}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                rightSectionPointerEvents={
                  comboboxLocation === null ? "none" : "all"
                }
                rightSection={
                  comboboxLocation !== null ? (
                    <CloseButton
                      size="sm"
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => setComboboxLocation(null)}
                      aria-label="Clear value"
                    />
                  ) : (
                    <Combobox.Chevron />
                  )
                }
              />
            </Combobox.Target>

            <Combobox.Dropdown>
              {/*TODO https://mantine.dev/combobox/?e=SelectGroups*/}
              <Combobox.Options>
                {locationOptions.length === 0 ? (
                  <Combobox.Empty>No locations found</Combobox.Empty>
                ) : (
                  locationOptions
                )}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>

          <Button
            className="min-w-fit flex-1"
            size="sm"
            variant="light"
            onClick={() => openEditLocationModal()}
            leftSection={<HiMap />}
          >
            Select in Map
          </Button>
        </div>
      </div>

      <Group className="w-full" justify="flex-end" mt="xl" mb="md">
        {/*<Button variant="subtle" onClick={onCancelClick}>
          Cancel
        </Button>*/}
        <Button disabled={!form.isValid()} onClick={onSaveClick}>
          Confirm
        </Button>
      </Group>

      <Modal
        opened={isEditLocationModalOpened}
        onClose={closeEditLocationModal}
        closeOnClickOutside={!loading}
        closeOnEscape={!loading}
        fullScreen={true}
        title={
          <div className="flex flex-row gap-2">
            <HiLocationMarker />
            <div>Select Location</div>
          </div>
        }
      >
        <MapView
          defaultSelectedLocation={form.values.locationId}
          onConfirm={(location) => {
            console.log("onEditLocationSubmit", location);
            form.setFieldValue("location", location);
            setComboboxLocation(location.name);
            closeEditLocationModal();
          }}
        ></MapView>

        {/*<EditLocation
          locationId={form.values.locationId}
          onSubmit={(location) => {
            console.log("onEditLocationSubmit", location);
            form.setFieldValue("location", location);
            setComboboxLocation(location.name);
            closeEditLocationModal();
          }}
        ></EditLocation>*/}
      </Modal>
    </div>
  );
}
