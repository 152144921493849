import {
  LoadingOverlay,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import useGlobalState, {
  globalProfile,
  globalSettings,
} from "../../globalState";
import { useAppSelector } from "../../app/hooks";

export default function Resolver(props: {
  children: React.ReactNode;
  waitFor: "profile" | "settings";
}) {
  const profileState = useAppSelector((state) => state.profile);

  const [settings, setSettings] = useGlobalState(globalSettings);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const computedColorScheme = useComputedColorScheme("dark");

  if (props.waitFor === "profile" && profileState.profile) {
    return props.children;
  }

  if (props.waitFor === "settings" && settings) {
    return props.children;
  }

  const isLoading = () => {
    if (props.waitFor === "profile") {
      return !profileState.profile;
    }
    if (props.waitFor === "settings") {
      return !settings;
    }
  };

  return (
    <LoadingOverlay
      visible={isLoading()}
      zIndex={400}
      overlayProps={{
        blur: "xl",
        color: computedColorScheme === "dark" ? "black" : "black",
        opacity: computedColorScheme === "dark" ? "70%" : "30%",
      }}
      loaderProps={{ color: "blue", type: "bars", size: "xl" }}
    >
      {props.children}
    </LoadingOverlay>
  );
}
