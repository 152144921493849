import {
  Button,
  Group,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { getAuth, sendEmailVerification } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";

export default function Step2(props: { onConfirm: any; onCancel: any }) {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const initialized = useRef(false);

  const [isEmailVerificationSent, setIsEmailVerificationSent] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const auth = getAuth();

  useEffect(() => {
    if (!initialized.current) { // Run only once even with strict mode enabled
      initialized.current = true;

      auth.currentUser.reload().then(() => {
        if (auth.currentUser?.emailVerified) {
          setIsEmailVerified(auth.currentUser?.emailVerified ?? false);
        } else {
          if (!isEmailVerificationSent) {
            console.log("Sending email verification");
            sendEmailVerification(getAuth().currentUser);
          }
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    if (isEmailVerified) {
      console.log("Email verified");

      // Refresh token to update the emailVerified property
      auth.currentUser.getIdToken(true).then((token) => {
        props.onConfirm();
      });
    }
  }, [isEmailVerified]);

  const onCancel = () => {
    console.log("Cancelled");
  };

  const onConfirm = () => {
    console.log("Confirmed");

    getAuth()
      .currentUser.reload()
      .then(() => {
        if (getAuth().currentUser.emailVerified) {
          setIsEmailVerified(true);
        } else {
          notifications.show({
            id: "error-unknown",
            color: "red",
            title: "Error while verifying email",
            message: "The email is not verified yet",
            autoClose: false,
          });
        }
      });
  };

  return (
    <div className="flex flex-col justify-between items-center h-full w-full max-w-[40rem]">
      <div className="flex flex-col w-full h-full pt-4">
        <h1 className="text-2xl mb-4">Verify your email</h1>
        <p>We sent you an email with a link to verify your email address.</p>
        <p className="mt-4 bg-yellow-600 bg-opacity-15 p-4 rounded-lg">
          If you don't see the email in your inbox, check your spam folder.
        </p>
      </div>

      <Group justify="center" mt="xl">
        <Button variant="default" onClick={onCancel}>
          Go back
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </Group>
    </div>
  );
}
