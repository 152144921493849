import { FolderDto } from '@braoshzmvavz/picnube-common';
import React from "react";
import { UpdateFolderAccessDto } from "../../model/UpdateFolderAccessDto";
import ManageFolderAccess from "../folder/ManageFolderAccess";

export default function ManageAccessModal(props: {
  folder: FolderDto;
  getFolderAccessInfo: (folderId: string) => Promise<any>;
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>;
}) {
  return (
    <div className="flex flex-col gap-4 w-full p-4 overflow-auto">
      <ManageFolderAccess
        folder={props.folder}
        getFolderAccessInfo={props.getFolderAccessInfo}
        updateFolderAccess={props.updateFolderAccess}
      ></ManageFolderAccess>
    </div>
  );
}
