import { Button } from "@mantine/core";
import React, { useState } from "react";
import { Drawer } from "vaul";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { MenuAction, MenuContext, MenuSection } from "../menu/menus";
import { HiChevronRight, HiCog, HiPlus, HiUsers } from "react-icons/hi";
import { stringToBgAndTextColors } from "src/utils/StringUtils";
import { setCurrentSpace } from "src/state/profile/profileSlice";
import {
  setFiles,
  setFolders,
  setNewFolder,
  setSpaceRootFolders,
} from "src/state/explorer/explorerSlice";
import useApi from "src/services/useApi";
import { SpaceDto } from "@braoshzmvavz/picnube-common";

export default function BottomDrawerSpaceSelector(props: {
  targetElement: React.ReactNode;
  onActionClick: (actionConfig: MenuAction, ctx: MenuContext) => void;
}) {
  const profileState = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const { fetchFolders } = useApi();

  const [isOpen, setIsOpen] = useState(false);

  const renderSpaceButtonNotSelected = (space: SpaceDto) => {
    const firstLetter = space.name.substring(0, 1);
    return (
      <div
        className="flex flex-row justify-between items-center p-2 px-4 gap-2 hover:bg-grey-900 cursor-pointer rounded-lg mx-1"
        onClick={() => {
          setIsOpen(false);
          dispatch(setCurrentSpace(space));
          dispatch(setFolders([]));
          dispatch(setSpaceRootFolders([]));
          dispatch(setFiles([]));
          dispatch(setNewFolder(null));
          localStorage.setItem("currentSpace", space.id);

          fetchFolders(space.id).then((response) => {
            if (response != null) {
              dispatch(setSpaceRootFolders(response));
              dispatch(setFolders(response));
            }
          });
        }}
      >
        <div className="flex flex-row justify-between items-center gap-2">
          <div
            className="flex justify-center items-center rounded-full min-w-8 min-h-8"
            style={{
              backgroundColor: stringToBgAndTextColors(space.name)
                .backgroundColor,
              color: stringToBgAndTextColors(space.name).textColor,
            }}
          >
            {firstLetter}
          </div>
          <div className="text-md">{space.name}</div>
        </div>

        <HiChevronRight className="text-lg min-w-fit"></HiChevronRight>
      </div>
    );
  };

  const renderOtherSpacesList = () => {
    const otherSpaces = profileState.profile?.spaces
      .filter((space) => space.id !== profileState.currentSpace.id)
      .map((space) => renderSpaceButtonNotSelected(space));

    if (!otherSpaces?.length) {
      return <></>;
    }

    return (
      <>
        {otherSpaces}
        <div className="border-b border-grey-800 w-full h-2" />
      </>
    );
  };

  const renderSpaceIcon = () => {
    if (!profileState.currentSpace) {
      return <div className="flex p-3"></div>;
    }

    const name = profileState.currentSpace.name;
    if (name == null) return null;

    const firstLetter = name.substring(0, 1);

    return (
      <div
        className={`flex justify-center items-center rounded-full w-8 h-8 min-w-8 min-h-8 pb-[2px] relative`}
        style={{
          backgroundColor: stringToBgAndTextColors(name).backgroundColor,
          color: stringToBgAndTextColors(name).textColor,
        }}
      >
        {firstLetter}
        {/* <div className="absolute top-4 left-4 w-full h-full flex justify-center items-center scale-75">
            <HiSwitchHorizontal className="w-4 h-4" style={{}} />
          </div> */}
      </div>
    );
  };

  const renderSpaceButton = () => {
    if (!profileState.currentSpace) {
      return <div className="flex p-3"></div>;
    }

    const name = profileState.currentSpace.name;
    if (name == null) return null;

    return (
      <div className="flex flex-row justify-between items-center py-4 px-4 gap-3 mx-1">
        {renderSpaceIcon()}
        <div className="flex flex-col justify-center items-start">
          <div className="text-md font-bold">{name}</div>

          <div className="text-sm text-grey-600">
            {profileState.currentSpace.description}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col">
        <div className="flex flex-col justify-between items-start">
          {renderSpaceButton()}

          <div className="w-full flex flex-col">
            <div className="flex flex-row justify-start items-center p-2 px-4 gap-2 hover:bg-grey-900 cursor-pointer rounded-lg mx-1">
              <HiCog></HiCog>
              <div className="text-sm">Settings</div>
            </div>

            <div className="flex flex-row justify-start items-center p-2 px-4 gap-2 hover:bg-grey-900 cursor-pointer rounded-lg mx-1">
              <HiUsers></HiUsers>
              <div className="text-sm">Manage users</div>
            </div>
          </div>

          <div className="border-b border-grey-800 w-full h-2" />
        </div>
        <div className="flex flex-col py-2">
          {renderOtherSpacesList()}
          <div className="flex justify-start items-center gap-2 p-2 px-4 cursor-pointer rounded-lg hover:bg-grey-900 active:bg-dark-999 text-sm mx-1 mt-2">
            <HiPlus></HiPlus>
            Add Space
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <Drawer.Trigger>{props.targetElement}</Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40 bottom-drawer-overlay" />
        <Drawer.Content className="bg-grey-900 flex flex-col rounded-t-[10px] mt-24 h-fit fixed bottom-0 left-0 right-0 outline-none bottom-drawer-content">
          <div aria-hidden className="mb-3" />
          <Drawer.Handle />
          <Drawer.Title />
          <Drawer.Description />
          <Drawer.Close />
          <div className="p-2 pt-0">{renderContent()}</div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
