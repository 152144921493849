import { Button } from "@mantine/core";
import React, { useState } from "react";
import { Drawer } from "vaul";
import { useAppSelector } from "../../app/hooks";
import { MenuAction, MenuContext, MenuSection } from "../menu/menus";

export default function BottomDrawer(props: {
  targetElement: React.ReactNode;
  topSection?: React.ReactNode;
  ctx: MenuContext;
  menuConfig: MenuSection[];
  actionsConfig?: MenuAction[];
  onActionClick: (actionConfig: MenuAction, ctx: MenuContext) => void;
}) {
  const profileState = useAppSelector((state) => state.profile);

  const [isOpen, setIsOpen] = useState(false);

  const showMenuAction = (menuAction: MenuAction) => {
    if (!!menuAction.showIf) {
      return menuAction.showIf(props.ctx, profileState.profile?.email);
    }
    return true;
  };

  const showMenuSection = (menuSection: MenuSection) => {
    return menuSection.actions.some(showMenuAction);
  };

  const renderMenu = () => {
    return props.menuConfig
      .filter((menuSection) => {
        if (!props?.actionsConfig && props.actionsConfig?.length == 0)
          return true;

        return menuSection.actions.some((menuAction) => {
          return !props.actionsConfig?.find(
            (actionConfig) => actionConfig.id === menuAction.id
          );
        });
      })
      .filter(showMenuSection)
      .map((menuSection, index1) => {
        return (
          <div key={index1 + "menuSection"}>
            <div className="mt-4 mb-2 text-sm font-bold text-grey-700">
              {menuSection.name}
            </div>

            <div className="flex flex-col gap-2">
              {menuSection.actions
                .filter((menuAction) => {
                  if (!props?.actionsConfig && props.actionsConfig?.length == 0)
                    return true;
                  return !props.actionsConfig?.find(
                    (actionConfig) => actionConfig.id === menuAction.id
                  );
                })
                .filter(showMenuAction)
                .map((menuAction, index2) => {
                  return (
                    <Button
                      variant="light"
                      color="gray"
                      {...menuAction.elementProps}
                      key={index1 + index2 + "menuAction"}
                      onClick={() => {
                        setIsOpen(false);
                        props.onActionClick(menuAction, props.ctx);
                      }}
                      leftSection={<menuAction.icon className="flex w-4 h-4" />}
                      disabled={
                        !!menuAction?.disabledIf
                          ? menuAction?.disabledIf(
                              props.ctx,
                              profileState.profile?.email
                            )
                          : false
                      }
                    >
                      <div className="flex flex-col">
                        {menuAction.name}
                        {menuAction?.description ? (
                          <div className="text-xs text-grey-600 mt-1">
                            {menuAction.description}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Button>
                  );
                })}
            </div>
          </div>
        );
      });
  };

  const renderTopSection = () => {
    if (!props?.actionsConfig && props.actionsConfig?.length == 0) return null;

    return (
      <div className="items-center p-4 pb-2 flex flex-row justify-between gap-2 xs:gap-6 overflow-auto">
        {props?.actionsConfig
          ?.filter(showMenuAction)
          .map((actionConfig, index) => {
            return (
              <div
                className="flex flex-col justify-around items-center gap-1 rounded-lg bg-dark-700 p-3 flex-1 min-h-24 min-w-24
                          dark:hover:bg-dark-900 hover:bg-grey-200 dark:active:bg-dark-999 active:bg-grey-300 cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                  props.onActionClick(actionConfig, props.ctx);
                }}
                key={index + actionConfig.name + "bottomDrawerAction"}
              >
                <actionConfig.icon className="text-4xl"></actionConfig.icon>
                <div className="text-xs font-bold text-center">
                  {actionConfig.name}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <Drawer.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <Drawer.Trigger>{props.targetElement}</Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40 bottom-drawer-overlay" />
        <Drawer.Content className="bg-grey-900 flex flex-col rounded-t-[10px] mt-24 h-fit fixed bottom-0 left-0 right-0 outline-none bottom-drawer-content">
          <div aria-hidden className="mb-3" />
          <Drawer.Handle />
          <Drawer.Title />
          <Drawer.Description />
          <Drawer.Close />
          {renderTopSection()}
          <div className="p-4 pt-0">{renderMenu()}</div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
