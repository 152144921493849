import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ProfileDto } from '@braoshzmvavz/picnube-common';
import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { routes } from ".";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import DemoBanner from "./components/demo/DemoBanner";
import Navbar from "./components/navbar/Navbar";
import Resolver from "./components/resolver/Resolver";
import useGlobalState, { globalSettings } from "./globalState";
import useApi from "./services/useApi";
import useApiDemo from "./services/useApiDemo";
import { useAuth } from "./services/useAuth";
import { Settings, SETTINGS_DEFAULTS } from "./settings";
import { setLocations } from "./state/locations/locationSlice";
import { setCurrentSpace, setProfile } from "./state/profile/profileSlice";

function App(props: { demo?: boolean }) {
  const dispatch = useAppDispatch();
  const profileState = useAppSelector((state) => state.profile);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const navigate = useNavigate();
  const { validateAuth, user, pending, isSignedIn } = useAuth();
  //const [profile, setProfile] = useGlobalState(globalProfile);
  const { fetchProfile, fetchLocations, fetchFolders, loading } = useApi();
  const { fetchProfile: fetchProfileDemo } = useApiDemo();
  const location = useLocation();
  const [settings, setSettings] = useGlobalState<Settings>(globalSettings);

  const currentOutlet = useOutlet();
  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {};

  useEffect(() => {
    if (pending) return;
    if (!user) validateAuth();
  }, [pending]);

  useEffect(() => {
    if (location == null) return;
    if (!pending && isSignedIn) fetchAndSaveProfile();
  }, [location, pending, isSignedIn]);

  useEffect(() => {
    if (props.demo) {
      // Set globalProfile
      fetchProfileDemo().then((response: ProfileDto) => {
        dispatch(setProfile(response));
      });

      // Set globalSettings
      const settings = localStorage.getItem("settings");
      if (settings != null && settings !== "" && settings !== undefined) {
        setSettings({
          ...SETTINGS_DEFAULTS,
          ...JSON.parse(settings),
        });
      } else {
        setSettings(SETTINGS_DEFAULTS);
      }
    }
  }, [props.demo]);

  const fetchAndSaveProfile = () => {
    if (!!profileState.profile) {
      return;
    }

    const currentSpaceId = localStorage.getItem("currentSpace");

    // Set globalProfile
    fetchProfile(currentSpaceId ?? null).then((response: ProfileDto) => {
      if (response === null) {
        navigate("/login");
        return;
      }
      console.log(response);

      const currentSpace = response.spaces.find(
        (space) => space.id === currentSpaceId
      );

      if (!currentSpace) {
        dispatch(setCurrentSpace(response.spaces[0]));
      } else {
        dispatch(setCurrentSpace(currentSpace));
      }

      fetchAndSaveLocations(currentSpace?.id ?? response.spaces[0].id);

      dispatch(setProfile(response));
    });

    // Set globalSettings
    const settings = localStorage.getItem("settings");
    if (settings != null && settings !== "" && settings !== undefined) {
      setSettings({
        ...SETTINGS_DEFAULTS,
        ...JSON.parse(settings),
      });
    } else {
      setSettings(SETTINGS_DEFAULTS);
    }
  };

  const fetchAndSaveLocations = (spaceId: string) => {
    fetchLocations(spaceId).then((response) => {
      console.log("fetchAndSaveLocations", response);
      if (response != null) {
        dispatch(setLocations(response));
      }
    });
  };

  const renderOutlet = () => {
    if (settings?.animations_enabled) {
      return (
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={300}
            classNames="page"
            unmountOnExit
          >
            {(state) => (
              <div ref={nodeRef as any} className="page h-full w-full">
                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      );
    }

    return (
      <div ref={nodeRef as any} className="page h-full w-full">
        {currentOutlet}
      </div>
    );
  };

  return (
    <div className="flex h-full w-full flex-col">
      <div className={`${props.demo ? "flex" : "hidden"} w-full`}>
        <DemoBanner></DemoBanner>
      </div>

      <div
        className={"flex h-full w-full " + (isMobile ? "flex-col" : "flex-row")}
      >
        <div>
          <Navbar demo={props.demo}></Navbar>
        </div>
        <div className="flex flex-col h-full w-full overflow-auto">
          <Resolver waitFor="settings">
            <div className="relative overflow-auto w-full h-full">
              {renderOutlet()}
            </div>
          </Resolver>
        </div>
      </div>
    </div>
  );
}

export default App;
