import { ProfileDto, SpaceDto } from '@braoshzmvavz/picnube-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

interface ProfileState {
    profile: ProfileDto;
    currentSpace: SpaceDto | null;
    loading: boolean;
    error: string | null;
}

const initialState: ProfileState = {
    profile: null,
    currentSpace: null,
    loading: false,
    error: null,
};

export const profileSlice = createSlice({
    name: 'profile',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetToInitialState: state => {
            state.profile = null
            state.currentSpace = null
            state.loading = false
            state.error = null
        },

        setProfile: (state, action: PayloadAction<ProfileDto>) => {
            state.profile = action.payload
        },

        addSpace: (state, action: PayloadAction<SpaceDto>) => {
            state.profile.spaces = [...state.profile.spaces, action.payload]
        },

        setCurrentSpace: (state, action: PayloadAction<SpaceDto>) => {
            state.currentSpace = action.payload;
        },
    }
})

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentSpace = (state: RootState) => state.profile.currentSpace;

export const {
    resetToInitialState,
    setProfile,
    addSpace,
    setCurrentSpace,
} = profileSlice.actions

export default profileSlice.reducer