import {
  Container,
  Divider,
  Drawer,
  Popover,
  Text,
  Tooltip,
  UnstyledButton,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { SpaceDto } from "@braoshzmvavz/picnube-common";
import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import {
  HiChevronRight,
  HiCog,
  HiFolder,
  HiHome,
  HiMap,
  HiMenu,
  HiOutlineLogout,
  HiPlus,
  HiSwitchHorizontal,
  HiUser,
  HiUsers,
  HiViewGrid,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import useApi from "src/services/useApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useGlobalState, { globalToken } from "../../globalState";
import { setCurrentSpace, setProfile } from "../../state/profile/profileSlice";
import { openLogoutModal } from "../modal/confirm";
import AppLogo from "../static/AppLogo";
import "./Navbar.css";
import {
  selectCurrentFolder,
  setFiles,
  setFolders,
  setNewFolder,
  setSpaceRootFolders,
} from "src/state/explorer/explorerSlice";
import { stringToBgAndTextColors } from "src/utils/StringUtils";
import BottomDrawerSpaceSelector from "../bottomSheet/BottomDrawerSpaceSelector";

interface NavbarLinkProps {
  icon?: typeof HiHome;
  iconComponent?: React.ReactNode;
  color?: string;
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export default function Navbar(props: { demo?: boolean }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { fetchFolders } = useApi();

  const [active, setActive] = useState(0);
  const [isSpacesPopoverOpened, setIsSpacesPopoverOpened] = useState(false);
  const [isSpacesPopoverMobileOpened, setIsSpacesPopoverMobileOpened] =
    useState(false);
  const { colorScheme, setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [mobileMenuOpened, { toggle }] = useDisclosure();
  const [opened, { open, close }] = useDisclosure(false);
  //const [profile, setProfile] = useGlobalState(globalProfile);

  const [token, setToken] = useGlobalState(globalToken);

  const location = useLocation();

  const explorerState = useAppSelector((state) => state.explorer);
  const profileState = useAppSelector((state) => state.profile);

  const getIconColor = (color: string) => {
    if (color) return color;
    return colorScheme === "dark"
      ? "var(--mantine-color-dark-2)"
      : "var(--mantine-color-dark-2)";
  };

  const getEmail = () => {
    if (props.demo) {
      if (!profileState.profile) return "";
      return profileState.profile.email;
    }

    return getAuth().currentUser?.email;
  };

  const getDisplayName = () => {
    if (props.demo) {
      if (!profileState.profile) return "";
      return profileState.profile.name;
    }

    return getAuth().currentUser?.displayName;
  };

  const getPhotoUrl = () => {
    if (props.demo) {
      return "https://picsum.photos/id/10/200/300";
    }

    return getAuth().currentUser?.photoURL;
  };

  const navbarPrimary = [
    {
      path: "/browse",
      icon: HiFolder,
      label: "Explorer",
      isActive: (path) => path.includes("/browse"),
      onClick: () => {
        // If already in explorer, do nothing
        if (location.pathname.includes("/browse")) {
          return;
        }

        // Otherwise, navigate to explorer taking into account the state
        if (explorerState.currentFolderId !== null) {
          navigate(
            `${props.demo ? "/demo" : ""}/browse/${
              explorerState.currentFolderId
            }`
          );
        } else {
          navigate(props.demo ? "/demo/browse" : "/browse");
        }
      },
    },
    {
      path: "/gallery",
      icon: HiViewGrid,
      label: "Gallery",
      onClick: () => navigate(props.demo ? "/demo/gallery" : "/gallery"),
    },
    { icon: HiMap, label: "Map", onClick: () => navigate("/map") },
  ];

  const navbarSecondary = [
    /*{
      icon: HiLightningBolt,
      label: "Upgrade account",
      color: colorScheme === "dark" ? "gold" : "goldenrod",
      onClick: () => console.log("upgrade account"),
    },*/ // TODO: add upgrade account page
    /*{
      icon: HiShieldExclamation,
      label: "Admin",
      color:
        colorScheme === "dark"
          ? "var(--mantine-color-orange-7)"
          : "var(--mantine-color-orange-6)",
      onClick: () => navigate("/admin"),
    },*/ // TODO: add admin page
    {
      path: "/account",
      iconComponent: isMobile ? (
        <div></div>
      ) : (
        <div
          className="flex flex-row justify-center items-center h-5 w-5 m-auto my-4
        rounded-full overflow-hidden"
        >
          {getPhotoUrl() ? (
            <img className="h-full w-full" src={getPhotoUrl()} alt="" />
          ) : (
            <HiUser
              className="h-full w-full"
              style={{ color: getIconColor(null) }}
            ></HiUser>
          )}
        </div>
      ),
      label: "Account",
      onClick: () => navigate(props.demo ? "/demo/account" : "/account"),
    },
    {
      path: "/settings",
      icon: HiCog,
      label: "Settings",
      onClick: () => navigate(props.demo ? "/demo/settings" : "/settings"),
    },
    {
      icon: HiOutlineLogout,
      label: "Logout",
      onClick: () =>
        openLogoutModal(() => {
          signOut(getAuth()).then(() => {
            setProfile(null);
            setToken(null);
            localStorage.removeItem("currentSpace");
            navigate("/login");
          });
        }),
    },
  ];

  // Remove upgrade account action if environment is private
  /*const isPrivateEnv = process.env.REACT_APP_ENVIRONMENT === "private";
  if (isPrivateEnv) {
    navbarSecondary.splice(0, 1);
  }*/

  const NavbarLink = ({
    icon: Icon,
    iconComponent,
    color,
    label,
    active,
    onClick,
  }: NavbarLinkProps) => {
    return (
      <Tooltip
        disabled={isMobile}
        label={label}
        position="right"
        withArrow
        arrowOffset={5}
        arrowSize={4}
        color="var(--mantine-color-dark-9)"
        transitionProps={{ duration: 200, transition: "fade" }}
      >
        <UnstyledButton
          onClick={onClick}
          className={
            "flex flex-row border-2 border-grey-800" +
            " " +
            (computedColorScheme === "dark"
              ? "link link--dark"
              : "link link--light") +
            (isMobile ? " rounded-lg" : " justify-center items-center")
          }
          data-active={active || undefined}
        >
          {iconComponent ? (
            iconComponent
          ) : (
            <div className="flex flex-row justify-start items-center p-4">
              <Icon
                className="flex w-5 h-5"
                style={{
                  color: getIconColor(color),
                }}
              />
              {isMobile ? (
                <Container hidden={!isMobile} className="flex flex-1">
                  {label}
                </Container>
              ) : (
                ""
              )}
            </div>
          )}
        </UnstyledButton>
      </Tooltip>
    );
  };

  const links = navbarPrimary.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={
        link.path === location.pathname ||
        (link?.isActive && link?.isActive(location.pathname))
      }
      onClick={() => {
        if (isMobile) {
          toggle();
        }
        setActive(index);
        link.onClick();
      }}
    />
  ));

  const actions = navbarSecondary.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={link.path === location.pathname}
      onClick={() => {
        if (isMobile) {
          toggle();
        }
        link.onClick();
      }}
    />
  ));

  const getNavbarStyle = () => {
    const baseStyle = isMobile
      ? "flex flex-row justify-between items-center h-14 min-h-14 border-b-2 "
      : "flex flex-col w-full h-full justify-between border-r-2 ";
    const borderStyle =
      computedColorScheme === "dark"
        ? "border-[var(--mantine-color-dark-7)]"
        : "";

    return baseStyle + borderStyle;
  };

  const renderMobileAccountButton = () => {
    if (!isMobile) return null;

    return (
      <div
        className="flex flex-col mt-auto rounded-lg p-4 gap-4 cursor-pointer dark:bg-dark-700 bg-grey-200 dark:hover:bg-dark-900 hover:bg-grey-300"
        onClick={() => {
          if (isMobile) {
            toggle();
          }
          navigate(props.demo ? "/demo/account" : "/account");
        }}
      >
        <div className="flex flex-row justify-start items-center gap-2 p-2 select-none flex-wrap">
          {getPhotoUrl() ? (
            <img
              className="h-10 w-10 min-h-10 min-w-10 m-auto rounded-full overflow-hidden"
              src={getPhotoUrl()}
              alt=""
            />
          ) : (
            <HiUser className="h-10 w-10 min-h-10 min-w-10 m-auto rounded-full overflow-hidden"></HiUser>
          )}

          <div className="flex flex-row justify-between flex-1 flex-start">
            <Container hidden={!isMobile} className="flex flex-col m-0 flex-1">
              <Text fz="lg" fw={500}>
                {getDisplayName()}
              </Text>
              <Text mt={2} fz="md" c="dimmed">
                {getEmail()}
              </Text>
            </Container>
            <div className="flex items-center justify">
              <HiChevronRight className="text-3xl" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSpaceIcon = () => {
    if (!profileState.currentSpace) {
      return <div className="flex p-3"></div>;
    }

    const name = profileState.currentSpace.name;
    if (name == null) return null;

    const firstLetter = name.substring(0, 1);

    return (
      <div
        className={`flex justify-center items-center rounded-full w-8 h-8 min-w-8 min-h-8 pb-[2px] relative`}
        style={{
          backgroundColor: stringToBgAndTextColors(name).backgroundColor,
          color: stringToBgAndTextColors(name).textColor,
        }}
      >
        {firstLetter}
        {/* <div className="absolute top-4 left-4 w-full h-full flex justify-center items-center scale-75">
          <HiSwitchHorizontal className="w-4 h-4" style={{}} />
        </div> */}
      </div>
    );
  };

  const renderSpaceButton = () => {
    if (!profileState.currentSpace) {
      return <div className="flex p-3"></div>;
    }

    const name = profileState.currentSpace.name;
    if (name == null) return null;

    return (
      <div className="flex flex-row justify-between items-center py-4 px-4 gap-3 mx-1">
        {renderSpaceIcon()}
        <div className="flex flex-col justify-center items-start">
          <div className="text-md font-bold">{name}</div>

          <div className="text-sm text-grey-600">
            {profileState.currentSpace.description}
          </div>
        </div>
      </div>
    );
  };

  const renderSpaceButtonNotSelected = (space: SpaceDto) => {
    const firstLetter = space.name.substring(0, 1);
    return (
      <div
        className="flex flex-row justify-between items-center p-2 px-4 gap-2 hover:bg-grey-900 cursor-pointer rounded-lg mx-1"
        onClick={() => {
          setIsSpacesPopoverOpened(false);
          setIsSpacesPopoverMobileOpened(false);

          dispatch(setCurrentSpace(space));
          dispatch(setFolders([]));
          dispatch(setSpaceRootFolders([]));
          dispatch(setFiles([]));
          dispatch(setNewFolder(null));
          localStorage.setItem("currentSpace", space.id);

          fetchFolders(space.id).then((response) => {
            if (response != null) {
              dispatch(setSpaceRootFolders(response));
              dispatch(setFolders(response));
            }
          });
        }}
      >
        <div className="flex flex-row justify-between items-center gap-2">
          <div
            className="flex justify-center items-center rounded-full min-w-8 min-h-8"
            style={{
              backgroundColor: stringToBgAndTextColors(space.name)
                .backgroundColor,
              color: stringToBgAndTextColors(space.name).textColor,
            }}
          >
            {firstLetter}
          </div>
          <div className="text-md">{space.name}</div>
        </div>

        <HiChevronRight className="text-lg min-w-fit"></HiChevronRight>
      </div>
    );
  };

  const renderOtherSpacesList = () => {
    const otherSpaces = profileState.profile?.spaces
      .filter((space) => space.id !== profileState.currentSpace.id)
      .map((space) => renderSpaceButtonNotSelected(space));

    if (!otherSpaces?.length) {
      return <></>;
    }

    return (
      <>
        {otherSpaces}
        <div className="border-b border-grey-800 w-full h-2" />
      </>
    );
  };

  const renderSpacesPopover = (isMobile: boolean = false) => {
    if (isMobile) {
      return (
        <BottomDrawerSpaceSelector
          onActionClick={() => {}}
          targetElement={
            <UnstyledButton
              onClick={() => {
                setActive(0);
                if (isMobile) {
                  setIsSpacesPopoverOpened(false);
                  setIsSpacesPopoverMobileOpened(!isSpacesPopoverMobileOpened);
                } else {
                  setIsSpacesPopoverMobileOpened(false);
                  setIsSpacesPopoverOpened(!isSpacesPopoverOpened);
                }
              }}
              className={
                "flex flex-row border-2 border-grey-800" +
                " " +
                (computedColorScheme === "dark"
                  ? "link link--dark"
                  : "link link--light") +
                (isMobile ? " rounded-lg" : "")
              }
              data-active={active || undefined}
            >
              <div className="flex flex-row justify-start items-center">
                {renderSpaceIcon()}
              </div>
            </UnstyledButton>
          }
        ></BottomDrawerSpaceSelector>
      );
    }

    return (
      <Popover
        width={300}
        position="right-end"
        withArrow
        arrowPosition="center"
        shadow="md"
        closeOnClickOutside={true}
        opened={isMobile ? isSpacesPopoverMobileOpened : isSpacesPopoverOpened}
        onOpen={() => {
          if (isMobile) {
            setIsSpacesPopoverMobileOpened(true);
            return;
          }
          setIsSpacesPopoverOpened(true);
        }}
        onClose={() => {
          if (isMobile) {
            setIsSpacesPopoverMobileOpened(false);
            return;
          }
          setIsSpacesPopoverOpened(false);
        }}
      >
        <Popover.Target>
          <div className="">
            <UnstyledButton
              onClick={() => {
                setActive(0);
                if (isMobile) {
                  setIsSpacesPopoverOpened(false);
                  setIsSpacesPopoverMobileOpened(!isSpacesPopoverMobileOpened);
                } else {
                  setIsSpacesPopoverMobileOpened(false);
                  setIsSpacesPopoverOpened(!isSpacesPopoverOpened);
                }
              }}
              className={
                "flex flex-row border-2 border-grey-800" +
                " " +
                (computedColorScheme === "dark"
                  ? "link link--dark"
                  : "link link--light") +
                (isMobile ? " rounded-lg" : "")
              }
              data-active={active || undefined}
            >
              <div className="flex flex-row justify-start items-center p-3">
                {renderSpaceIcon()}
              </div>
            </UnstyledButton>
          </div>
        </Popover.Target>
        <Popover.Dropdown className="dark:bg-dark-100 bg-grey-200">
          <div className="max-h-[90vh] overflow-auto">
            <div className="w-full flex flex-col">
              <div className="flex flex-col justify-between items-start">
                {renderSpaceButton()}

                <div className="w-full flex flex-col">
                  <div className="flex flex-row justify-start items-center p-2 px-4 gap-2 hover:bg-grey-900 cursor-pointer rounded-lg mx-1">
                    <HiCog></HiCog>
                    <div className="text-sm">Settings</div>
                  </div>

                  <div className="flex flex-row justify-start items-center p-2 px-4 gap-2 hover:bg-grey-900 cursor-pointer rounded-lg mx-1">
                    <HiUsers></HiUsers>
                    <div className="text-sm">Manage users</div>
                  </div>
                </div>

                <div className="border-b border-grey-800 w-full h-2" />
              </div>
              <div className="flex flex-col py-2">
                {renderOtherSpacesList()}
                <div className="flex justify-start items-center gap-2 p-2 px-4 cursor-pointer rounded-lg hover:bg-grey-900 active:bg-dark-999 text-sm mx-1 mt-2">
                  <HiPlus></HiPlus>
                  Add Space
                </div>
              </div>
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    );
  };

  return (
    <>
      <Container
        visibleFrom="xs"
        p={0}
        bg={
          computedColorScheme === "dark"
            ? "#111212"
            : "var(--mantine-color-gray-2)"
        }
        className={getNavbarStyle()}
      >
        <div className="flex flex-col">
          {renderSpacesPopover()}
          <div className="flex flex-col">{links}</div>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col">{actions}</div>
        </div>
      </Container>

      <Container
        hiddenFrom="xs"
        bg={computedColorScheme === "dark" ? "#111212" : "#111212"}
        className={getNavbarStyle()}
      >
        <div>
          <div
            onClick={toggle}
            className="flex
            hover:bg-grey-900 active:bg-dark-999 cursor-pointer
            rounded-xl p-2 ml-[-0.5rem]"
            data-active={active || undefined}
          >
            <HiMenu className="w-5 h-5 text-grey-400" />
          </div>

          <Drawer
            opened={isMobile && mobileMenuOpened}
            onClose={toggle}
            title={
              <div className="flex items-center gap-1 text-xl">
                <AppLogo size={30}></AppLogo>
                <h1 className="h-full">{process.env.REACT_APP_APPNAME}</h1>
              </div>
            }
          >
            <div className="flex-1">
              <div className="flex flex-col">
                <div className="flex flex-col">{links}</div>
              </div>
              <Divider my={18}></Divider>
              <div className="flex flex-col">
                <div className="flex flex-col">{actions}</div>
              </div>
            </div>

            {renderMobileAccountButton()}
          </Drawer>
        </div>
        <div className="flex flex-col justify-center items-center cursor-pointer">
          {renderSpacesPopover(true)}
        </div>
      </Container>
    </>
  );
}
