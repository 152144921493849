import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../../model/Location';

interface LocationState {
    locations: Location[];
    loading: boolean;
    error: string | null;
}

const initialState: LocationState = {
    locations: [],
    loading: false,
    error: null,
};

export const locationSlice = createSlice({
    name: 'location',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetToInitialState: state => {
            state.locations = []
            state.loading = false
            state.error = null
        },

        setLocations: (state, action: PayloadAction<Location[]>) => {
            state.locations = [...action.payload]
        },

        addLocation: (state, action: PayloadAction<Location>) => {
            state.locations = [...state.locations, action.payload]
        },

        updateLocationState: (state, action: PayloadAction<Location>) => {
            console.log('UPDATELOCATIONSTATE', state.locations, action.payload)
            state.locations = state.locations.map((location) => {
                if (location.id === action.payload.id) {
                    return action.payload;
                }
                return location;
            });
        },
    }
})

export const {
    resetToInitialState,
    setLocations,
    addLocation,
    updateLocationState,
} = locationSlice.actions

export default locationSlice.reducer